import React, {useEffect, useState} from 'react';

import {Link} from 'react-router-dom';

import lottie from 'lottie-web';

import CallToAction from './components/callToAction.jsx';

import Title from './components/headings/title.jsx';
import Subtitle from './components/headings/subtitle.jsx';
import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import EmailReachOutSecondary from './components/buttons/emailReachOutSecondary.jsx';
import H1 from './components/headings/h1.jsx';
import H2 from './components/headings/h2.jsx';
import H3 from './components/headings/h3.jsx';
import H4 from './components/headings/h4.jsx';
import Description from './components/texts/description.jsx';
import Body from './components/texts/body.jsx';
import useNavigateGA from './hooks/useNavigateGA.jsx';

import lottieCalendarJson from '../src/js/animations/lottieCalendar.json';
import lottieCalculatorJson from '../src/js/animations/lottieCalculator.json';
import lottieClockJson from '../src/js/animations/lottieClock.json';
import lottieCursorClickJson from '../src/js/animations/lottieCursorClick.json';
import lottieCogJson from '../src/js/animations/lottieCog.json';

export default function Home() {

  let lottieCalendarContainer = React.createRef();
  let lottieCalculatorContainer = React.createRef();
  let lottieClockContainer = React.createRef();
  let lottieCursorClickContainer = React.createRef();
  let lottieCogContainer = React.createRef();

  const navigate = useNavigateGA();
  const [howItWorks, setHowItWorks] = useState("Brands");

  useEffect(() => {
    lottie.loadAnimation({
      container: lottieCalendarContainer.current,
      animationData: lottieCalendarJson
    })

    lottie.loadAnimation({
      container: lottieCalculatorContainer.current,
      animationData: lottieCalculatorJson
    })

    lottie.loadAnimation({
      container: lottieCursorClickContainer.current,
      animationData: lottieCursorClickJson
    })

    lottie.loadAnimation({
      container: lottieClockContainer.current,
      animationData: lottieClockJson
    })

    lottie.loadAnimation({
      container: lottieCogContainer.current,
      animationData: lottieCogJson
    })
  }, []);

  return <>
    <div className='mesh-gradient-8'>
      <div className='container mx-auto px-4 md:px-0 pb-28 md:pb-48'>
        <div className='pt-5 mb-16 text-center'>
          <span className='mr-2 block md:inline-block font-light  text-[#F6F6F5] leading-3'>
            *Earn cash back from your adult beverage purchases <span className='inline-block'>through the <img alt='BYBE' className='inline w-[40px] h-[40px] mx-1 mb-2' src="/images/bybeApp/bybeAppLogoWhite.svg" /> app.</span>
          </span>


          <ButtonPrimary url={'https://bybeapp.com/'} gaEventCategory={'home-page-click'} gaEventAction={'click-to-view-offers--home'} gaEventLabel={'view-offers'} text="View offers" newWindow mobileInline darkBG />
        </div>

        <div className='text-center'>
          <Title white>Promotion platform&nbsp;
          <span className='xl:block'>
            <span className='inline-block'>for beer,</span> <span className='inline-block'>wine & spirits</span>
          </span>
          </Title>

          <Subtitle lightYellow>The adult beverage industry's one-stop shop for digital offer management and distribution.</Subtitle>

          <div className='inline-block mb-10'>
            <ButtonPrimary url={'/products'} gaEventCategory={'home-page-click'} gaEventAction={'click-to-products'} text="View products" marginRight nowrap md mobileInline darkBG reactLink />

            <EmailReachOutSecondary email="info@bybeapp.com" subject="Inquiry from BYBE.com" _blank text="Contact us" gaEventCategory={"home-page-click"} gaEventAction={"click-to-contact-us"} gaEventLabel={"contact-us"} newWindow md mobileInline white darkBG/>
          </div>
        </div>
      </div>
    </div>

    <div className='mb-10 mt-[-7rem] md:mt-[-12rem]'>
      <div className='text-center'>
        <img src={'/images/dashboard-ui.png'} className="mx-auto" alt="" />
      </div>
    </div>

    <div className='container mx-auto px-4 md:px-0'>
      <div className='mb-16 md:mb-24'>
        <div className='mb-10'>
          <H1 center>One-stop platform for managing promotions</H1>

          <Description>
            <div className='twoColumn md:grid grid-cols-2 md:space-x-24'>
              <div>We are building the industry leading platform for beer, wine and spirit promotions by creating products based on regulatory requirements. Manage promotions directly in the BYBE dashboard. Distribute promotions on the BYBE app, brand pages, and inside retail apps in our partner network. </div>
              <div>The suite of features enables brands to distribute promotions, pay out consumers, and clear all consumer rewards directly through one tech stack</div>
            </div>
          </Description>
        </div>

        <div className='mb-10 grid grid-cols-2 md:grid-cols-4 md:space-x-24'>
          <div>
            <div className='mx-auto mb-5 w-[65px] h-[65px]' ref={lottieCalendarContainer} />
            <H3 center>Offer <br/> Management</H3>
          </div>

          <div>
            <div className='mx-auto mb-5 w-[65px] h-[65px]' ref={lottieCalculatorContainer} />
            <H3 center>Budget <br/> Controls</H3>
          </div>

          <div>
            <div className='mx-auto mb-5 w-[65px] h-[65px]' ref={lottieCursorClickContainer} />
            <H3 center>In-Store <br/> Activation</H3>
          </div>

          <div>
            <div className='mx-auto mb-5 w-[65px] h-[65px]' ref={lottieClockContainer} />
            <H3 center>Real-Time <br/> Results</H3>
          </div>
        </div>

        <ButtonPrimary url={"/products"} gaEventCategory={'home-page-click'} gaEventAction={'click-to-products'} text="View products" center md nowrap reactLink />
      </div>
    </div>

    <div className='mesh-gradient-5'>
      <div className='container mx-auto px-4 md:px-0 mb-16 md:mb-24 py-16'>
        <H1 mobileCenter light>How it works for&#8230;</H1>

        <div className='w-24 mx-auto' ref={lottieCogContainer}/>

        <div className='flex justify-center gap-x-4 md:gap-x-8 my-16 text-xl '>
          <button className={`${howItWorks === "Brands" ? "bg-light-purple text-white border-2 border-light-purple" : "border-2 border-white text-white "}  rounded-lg font-bold text-base md:text-lg height-[44px] drop-shadow-lg w-40 duration-500`} onClick={() => setHowItWorks("Brands")}>Brands</button>
          <button className={`${howItWorks === "Retailers" ? "bg-light-purple text-white border-2 border-light-purple" : "border-2 border-white text-white "}  rounded-lg font-bold text-base md:text-lg height-[44px] drop-shadow-lg w-40 duration-500`} onClick={() => setHowItWorks("Retailers")}>Retailers</button>
          <button className={`${howItWorks === "Developers" ? "bg-light-purple text-white border-2 border-light-purple" : "border-2 border-white text-white "}  rounded-lg font-bold text-base md:text-lg height-[44px] drop-shadow-lg w-40 duration-500`} onClick={() => setHowItWorks("Developers")}>Developers</button>
        </div>

        <div>
          {howItWorks === "Brands" ?
            <div className='grid grid-cols-3 justify-center gap-x-10 md:gap-x-24'>
              <div className='text-center'>
                <H4 mintGreen>Manage</H4>
                <Body light h72>BYBE’s dashboard makes offer management simple.</Body>
              </div>
              <div className='text-center'>
                <H4 mintGreen>Distribute</H4>
                <Body light h72>Distribute offers to retailers and partners in BYBE’s network.</Body>
              </div>
              <div className='text-center'>
                <H4 mintGreen>Analyze</H4>
                <Body light h72>Pay-per-performance model makes ROI and budget controls easy.</Body>
              </div>
            </div>
          : <></>}

          {howItWorks === "Retailers" ?
            <div className='grid grid-cols-3 justify-center gap-x-10 md:gap-x-24'>
              <div className='text-center'>
                <H4 mintGreen>Source</H4>
                <Body light h72>Our Retail API makes it easy to incorporate supplier-funded promotions.</Body>
              </div>
              <div className='text-center'>
                <H4 mintGreen>Add value</H4>
                <Body light h72>Consumers save more, at no cost or margin impact.</Body>
              </div>
              <div className='text-center'>
                <H4 mintGreen>Track</H4>
                <Body light h72>Incremental loyalty engagement and sales.</Body>
              </div>
            </div>
          : <></>}

          {howItWorks === "Developers" ?
          <div className='grid grid-cols-3 justify-center gap-x-10 md:gap-x-24'>
            <div className='text-center'>
              <H4 mintGreen>Lean on</H4>
              <Body light h72>Our compliance and adult beverage category expertise.</Body>
            </div>
            <div className='text-center'>
              <H4 mintGreen>Leverage</H4>
              <Body light h72>Our supplier relationships to access promotions.</Body>
            </div>
            <div className='text-center'>
              <H4 mintGreen>Integrate</H4>
              <Body light h72>With our team and Retail API or SFTP products.</Body>
            </div>
          </div>
          : <></>}
        </div>
      </div>
    </div>

    <div className='container mx-auto px-4 md:px-0'>
      <div className='mb-16 md:mb-24'>
        <H1>The go-to adult beverage solution</H1>

        <Body>We work diligently with retailers, distributors, and regulators as we built the standard for beer, wine and spirit promotions. Today, leading suppliers and retailers drive sales through promotions. Our team and partners are passionate about amplifying beer, wine and spirit promotions.</Body>

        <div className='threeColumn grid grid-cols-3  md:space-y-0 gap-x-5 text-center md:gap-x-28 text-lg md:text-2xl'>
          <div>
            <div className='text-3xl md:text-6xl text-orange'>100+</div>
            suppliers in the world use BYBE
          </div>

          <div>
            <div className='text-3xl md:text-6xl text-orange'>$5M</div>
            in consumer savings delivered
          </div>

          <div>
            <div className='text-3xl md:text-6xl text-orange'>99.8%</div>
            email delivery rate
          </div>
        </div>
      </div>

      <div className='twoColumn mb-16 md:mb-24 md:grid grid-cols-2 md:space-x-28'>
        <div>
          <H2>Adult Beverage specialists, with compliance as the core feature</H2>
          <Body>When we started BYBE, we tried using existing digital coupon tools and retail media networks to amplify our beer, wine and spirit partners promotions and messaging. We found the unique regulatory requirements limited our ability to participate. In response, we built products specifically for adult beverage retailers and suppliers. The result: empowered beer, wine and spirits activation in digital initiatives.</Body>
          <span className='hidden md:block'>
              {/* <ButtonPrimary url={"/company/#active-markets"} gaEventCategory={'home-page-click'} gaEventAction={'click-to-active-markets'} alt={'View active markets'} text="View active markets" mobileCenter /> */}

              <div className= {`text-center md:text-left`}>
                <Link to="/company#activeMarkets" className='inline-block' onClick={event => navigate({event, gaEventCategory:'home-page-click', gaEventAction:'click-to-active-markets'})}>
                    <button className={`py-2 px-4 bg-light-purple font-bold text-white text-base md:text-lg rounded-lg height-[44px] drop-shadow-lg duration-500 hover:bg-dark-purple`}>
                        View active markets
                    </button>
                </Link>
              </div>
          </span>
        </div>
        <div>
          <Link to="/company/#activeMarkets" onClick={event => navigate({event, gaEventCategory:'home-page-click', gaEventAction:'click-to-active-markets'})} alt="View active markets">
            <img className='mx-auto w-3/4' src='/images/state-map-circle.png' />
          </Link>
        </div>
      </div>

      <div className='mb-16 md:mb-24'>
        <div className='w-3/4'>
          <H2>Easily distribute promotions with BYBE for every consumer journey</H2>

          <Body>Beer, wine and spirits promotions should be as easy to incorporate in digital initiatives as every other CPG category. Compliance is our foundational product requirement. Leverage and deploy our tools to activate supplier-funded promotions across multiple consumer touch points.</Body>
        </div>
      </div>
    </div>

    <CallToAction title={'Ready to get started?'}
      primarySubTitle={'Pay for what matters: redemptions. View pay-per-performance pricing.'}
      primaryButtonText={'Pricing'}
      primaryButtonURL={'/pricing'}
      secondarySubTitle={'Embed offers where consumers seek information.'}
      secondaryButtonText={'Documentation'}
      secondaryButtonURL={'/developers'}
    />
  </>;
}