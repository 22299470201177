import React, {useRef, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import CallToAction from './components/callToAction.jsx';

import Subtitle from './components/headings/subtitle.jsx'
import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import H1 from './components/headings/h1.jsx'
import H4 from './components/headings/h4.jsx'
import Description from './components/texts/description.jsx'
import Body from './components/texts/body.jsx'

import GraphWrapper from './components/graphWrapper.jsx';
import UsStatesMap from './components/d3/usStatesMap.jsx';
import useScrollToRefSection from './hooks/useScrollToRefSection';

export default function Company(){

    const redemptionsByStateUsStatesMapData = 
    [
        {
            "state": "Alaska",
            "redemptions": 4,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Alabama",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Arkansas",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Arizona",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "California",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Colorado",
            "redemptions": 7,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Connecticut",
            "redemptions": 1,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Washington DC",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Delaware",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Florida",
            "redemptions": 4,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Georgia",
            "redemptions": 5,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Hawaii",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0
        },
        {
            "state": "Iowa",
            "redemptions": 5,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Idaho",
            "redemptions": 2,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Illinois",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Indiana",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Kansas",
            "redemptions": 1,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Kentucky",
            "redemptions": 2,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Louisiana",
            "redemptions": 0,
            "accepted": 70,
            "disbursed": 0,
            "beer": "No",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Massachusetts",
            "redemptions": 2,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Maryland",
            "redemptions": 2,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Maine",
            "redemptions": 5,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Michigan",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Minnesota",
            "redemptions": 7,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Mississippi",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Missouri",
            "redemptions": 0,
            "accepted": 30,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "Yes"
        },
        {
            "state": "Montana",
            "redemptions": 100,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "North Carolina",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "North Dakota",
            "redemptions": 0,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Nebraska",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "New Hampshire",
            "redemptions": 5,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "New Jersey",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "New Mexico",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Nevada",
            "redemptions": 1,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "New York",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Ohio",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Oklahoma",
            "redemptions": 6,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Oregon",
            "redemptions": 5,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Pennsylvania",
            "redemptions": 4,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Puerto Rico",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Rhode Island",
            "redemptions": 4,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "South Carolina",
            "redemptions": 2,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
            
        },
        {
            "state": "South Dakota",
            "redemptions": 3,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Tennessee",
            "redemptions": 4,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Texas",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Utah",
            "redemptions": 0,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Virginia",
            "redemptions": 6,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Vermont",
            "redemptions": 2,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Washington",
            "redemptions": 5,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Wisconsin",
            "redemptions": 2,
            "accepted": 100,
            "disbursed": 0,
            "beer": "Yes",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "West Virginia",
            "redemptions": 0,
            "accepted": 70,
            "disbursed": 0,
            "beer": "No",
            "wine": "Yes",
            "spirits": "Yes"
        },
        {
            "state": "Wyoming",
            "redemptions": 3,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        },
        {
            "state": "Hawaii",
            "redemptions": 3,
            "accepted": 0,
            "disbursed": 0,
            "beer": "No",
            "wine": "No",
            "spirits": "No"
        }
    ]

    const sectionRefs = {
        activeMarkets: useRef(),
        partners: useRef(),
        about: useRef(),
        jobs: useRef()
    }

    useScrollToRefSection(sectionRefs);
    
    return <>
        <div className='mesh-gradient-5-sm'>
            <div className='container mx-auto px-4 md:px-0 pt-16 md:pt-24'>
                <div className='pb-14 md:pb-24 lg:w-2/3'>
                    <H1 light>Vision</H1>
                    
                    <Subtitle lightYellow>Eliminate “we have to check with legal”</Subtitle>

                    <Body noBottomPadding light>Enable adult beverage brands to go-to-market like every CPG company. Empower suppliers to push their brands wherever consumers seek product information, and let us manage the complexity of regulatory compliance.</Body> 
                </div>
            </div>
        </div>
        
        <div className='container mx-auto px-4 md:px-0'> 
            <div ref={sectionRefs['activeMarkets']} className='mt-16 md:mt-24 mb-16 md:mb-24'>
                <div className='lg:w-2/3'>
                    <H1>Active markets</H1>
                
                    <Description>We include each state’s regulations as product requirements. Our goal is to be transparent, proactive and actively engage the regulatory community as it evolves. Our company thrives at the intersection of technology and regulation.</Description>
                </div>
                
                <GraphWrapper>
                    <UsStatesMap id='1'
                            data={redemptionsByStateUsStatesMapData}
                            dataKey='accepted'
                            tooltipKeys={[{display: 'Beer', key: 'beer'}, {display: 'Wine', key: 'wine'}, {display: 'Spirits', key: 'spirits'}]} />
                </GraphWrapper>

                <div className="d3-us-states-map-tooltip"></div>
                
                <div className='lg:w-2/3 lg:mx-auto'>

                    <Body><span className='hidden'>Alaska, Arizona, California, Colorado, Connecticut, Delaware, Florida, Georgia, Idaho, Illinois, Iowa, Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Mexico, New York, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, South Carolina, South Dakota, Tennessee, Vermont, Virginia, Washington, West Virginia, Wisconsin, Wyoming</span></Body>
                
                    <div className='grid grid-cols-3 gap-x-6'>
                        <div>
                            <H4 orange>Beer</H4>
                            <Body noBottomPadding>AK, AZ, CA, CO, CT, DC, DE, FL, GA, IA, ID, IL, KS, KY, MA, MD, ME, MI, MN, MT, ND, NE, NH, NM, NV, NY, OH, OK, OR, RI, SD, SC, TN, VA, VT, WA, WI</Body>
                        </div>
                        
                        <div>
                            <H4 orange>Wine</H4>
                            <Body noBottomPadding>AK, AZ, CA, CO, CT, DC, DE, FL, GA, IA, ID, IL, KS, KY, LA, MA, MD, ME, MI, MN, MT, ND, NE, NH, NM, NV, NY, OH, OK, OR, RI, SD, SC, TN, VA, VT, WA, WI, WV</Body>
                        </div>
                    
                        <div>
                            <H4 orange>Spirits</H4>
                            <Body noBottomPadding>AK, AZ, CA, CO, CT, DC, DE, FL, GA, IA, ID, IL, KS, KY, LA, MA, MD, ME, MI, MN, MO, MT, ND, NE, NH, NM, NV, NY, OH, OK, OR, RI, SD, SC, TN, VA, VT, WA, WI, WV</Body>    
                        </div>
                    </div>
                </div>

            </div>

            <div ref={sectionRefs['partners']} className='mb-16 md:mb-24'>
                <H1 center>Partners</H1>
            
                <div className='grid grid-cols-3 gap-10 justify-items-center items-center'>
                    <img className='' src='/images/partners/avidMarketingGroup.png' />
                    <img className='w-3/4' src="/images/partners/KRS.webp" />
                    <img className='' src="/images/partners/paytronix.jpeg" />
                    <img className='w-3/4' src="/images/partners/rovertown.png" />
                    <img className='' src="/images/partners/collaborativeMarketingGroup.png" />
                </div>
            </div>

            {/* <div id="mentions" className='pt-20 md:pt-0 mb-8 md:mb-48'>
                <div className='mb-10'>
                    <div className='mb-10'>
                        <H1>Here are some of our mentions...</H1>
                    </div>
                    
                    <article className='mb-10'>
                        <div className='mb-5'>
                            <h3 className='font-bold text-lg'>Blog Title</h3>
                            <span className='text-sm uppercase'>FEB 12 2022</span>
                        </div>
                        

                        <img src="http://place-hold.it/400x300" alt=""  className='mb-5'/>

                        <Body>
                            <span className='block w-2/3'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula mauris pellentesque libero lacinia, id cursus ipsum pretium. Sed tortor arcu, ornare nec volutpat at, ultricies nec quam.
                            </span>
                        </Body>    
                    </article>
                    
                    <article className='mb-10'>
                        <div className='mb-5'>
                            <h3 className='font-bold text-lg'>Blog Title</h3>
                            <span className='text-sm uppercase'>FEB 12 2022</span>
                        </div>
                        

                        <img src="http://place-hold.it/400x300" alt=""  className='mb-5'/>

                        <Body>
                            <span className='block w-2/3'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula mauris pellentesque libero lacinia, id cursus ipsum pretium. Sed tortor arcu, ornare nec volutpat at, ultricies nec quam.
                            </span>
                        </Body>    
                    </article>    
                </div>

                <ButtonPrimary text="View more posts" />
            </div> */}

            <div ref={sectionRefs['about']} className='mb-16 md:mb-24 twoColumn lg:grid grid-cols-2 md:gap-x-24'>
                <div>
                    <H1>About BYBE, inc.</H1>

                    <Description>
                        <span className='block mb-5'>
                            Like many product companies, we started BYBE to solve our own pain point. Working in the beer, wine and spirit industry, we observed the rise of retail apps as an extension of ecommerce and loyalty programs. Other categories thrived connecting with consumers, but the adult beverage category was absent due to legal uncertainty. 
                        </span>

                        <span className='block mb-5'>
                            We tried working with legacy coupon and cashback platforms, but they didn’t understand the nuance of the three-tiered system and regulatory best practices. Understanding each state has their own best practices, we sought out to include each state’s regulations as product requirements. With a key goal: be the most transparent third-party technology provider in the industry. 
                        </span>

                        <span className='block mb-5'>
                            Five years later, we continue leading the industry in digitization, transparency and innovation.
                        </span> 
                    </Description>
                </div>
            </div>
        </div>    

        <CallToAction title={'Interested in joining the team?'} 
            primaryButtonText={'View job openings'} 
            primaryButtonURL={'https://www.linkedin.com/company/bybe-app/jobs/'} 
        />
        
    </>;
}