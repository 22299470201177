import React from 'react';

export default function({children, center, light, h72, noBottomPadding}){
    
    return <div className={` text-sm md:text-base
        ${noBottomPadding ? `` : "mb-5"}  
        ${center ? "text-center" : ``}
        ${light? `text-[#F6F6F5]`: ``}
        ${h72 ? `h-[100px]`: ``}
    `}>{children}</div>

}