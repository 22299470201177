import React from 'react';

export default function({children, center, mobileCenter, light, noBottomMargin}){
    
    return <h1 className={`
        ${noBottomMargin ? `` : 'mb-5 md:mb-10'}
        text-3xl md:text-4xl font-bold tracking-[-0.05em] 
        ${light ? 'text-white' : 'text-dark-purple'} 
        ${center ? 'md:text-center': ``} 
        ${mobileCenter ? 'text-center': ``}
    `}>{children}</h1>;

}