import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Context from '../context.jsx';

export default function useNavigateGA() {

    const history = useHistory();
    const {gaEvent} = useContext(Context);

    return function navigate({event, gaEventCategory, gaEventAction, gaEventLabel, newWindow}) {
        event.preventDefault();
        gaEvent(gaEventCategory, gaEventAction, gaEventLabel);
        if (newWindow) window.open(event.currentTarget.href, event.currentTarget.target);
        else history.push(event.currentTarget.pathname+(event.currentTarget.hash ? event.currentTarget.hash : ''));
    }
}