import React from 'react';

import CallToAction from './components/callToAction.jsx';
import Subtitle from './components/headings/subtitle.jsx'
import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import ButtonSecondary from './components/buttons/buttonSecondary.jsx';
import H1 from './components/headings/h1.jsx';
import H2 from './components/headings/h2.jsx';
import Description from './components/texts/description.jsx';
import Body from './components/texts/body.jsx';
import useNavigateGA from './hooks/useNavigateGA.jsx';

export default function ForRetailers(){

    const navigate = useNavigateGA();

    return <>
    <div className='mesh-gradient-9'>
        <div className='container mx-auto px-4 md:px-0 pt-16 md:pt-24'>
            <div className='pb-16 md:pb-24'>
                <H1 light>We operate at the intersection of regulation and technology</H1>
                
                <Subtitle lightYellow>From omnichannel to loyalty, our products enable supplier promotions to live in retail loyalty and digital products. </Subtitle>    
                
                <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'for-retailers-page-click'} gaEventAction={'click-to-req-demo'} newWindow text='Schedule demo' lg mobileCenter _blank darkBG/>
            </div>
        </div>
    </div>
    
    <div className='container mx-auto px-4 md:px-0 mt-16 md:mt-24'>
        <div className='mb-16 md:mb-24 twoColumn md:grid grid-cols-2 grid-rows-2 gap-10'>
            <div className='text-center'>
                <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/balloons.svg" />
                <H2>Free access for retailers</H2>
                <Body>Include supplier-funded cash back beer, wine and spirit promotions inside retail and ecommerce apps/sites.</Body>
            </div>

            <div className='text-center'>
                <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/management.svg" />
                <H2>Compliant and domain experts</H2>
                <Body>Consumer support questions and reach outs handled by our Client Success team.</Body>
            </div>

            <div className='text-center'>
                <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/increase.svg" />
                <H2>Increase digital engagement</H2>
                <Body>Expand content and savings opportunities for retail loyalty users with state by state compliance built in.</Body>
            </div>

            <div className='text-center'>
            <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/customer-data.svg" />
                <H2>Own your customer data</H2> 
                <Body>Leverage supplier-funded content to increase digital engagement and usage through existing push notifications and email campaigns.</Body> 
            </div>
        </div>

        {/* <div className='mb-10'>
            <H1>Case Study</H1>
        </div>

        <div className='md:flex mb-28 md:mb-48 md:space-x-24'>
            <div className='md:w-1/2'>    
                <H2>Problem</H2>
                
                <Description>
                    A major retailer’s presence was primarily in a mail-in rebate market. They need to incorporate supplier funded promotions in a loyalty app.
                </Description>

                <Body>
                    <span className='block leading-8'>
                        <span className='block mb-10'>
                            A major retailer invested hundreds of millions of dollars into a loyalty program with self-managed and third party coupons. They were already working with a third-party to source instant redeemable digital coupons (IRC’s), funded directly by CPG companies. The retailer wanted to enable their users to take advantage of adult beverage supplier funded promotions. They didn’t want to recommend a third-party, non retail app for beer, wine and spirit promotions, but their own loyalty app for everything else. They wanted their call to action to drive consumers to their app for all categories, including beer, wine and spirits.   
                        </span>

                        They were concerned about legal implications and risks associated with allowing an existing clearinghouse for other categories to also manage adult beverage promotions. They sought to completely remove themselves from processing any supplier promotional budgets.   
                    </span>
                </Body>

                <H2>Solution</H2>
                
                <Description>
                    The retailer leveraged BYBE’s Retail API to enable cash back promotions directly in their mobile app, in Mail-In Rebate markets and Instantly Redeemable Coupon markets.
                </Description>

                <Body>
                    <div className='leading-8'>
                        The retailer considered using their existing third party coupon provider for IRC markets, but instead chose to create a cashback experience through BYBE’s Retail API across beer, wine and spirits. Even where IRC’s are legal, they recognized consumers don’t need the additional complication or explanation of why a spirit brand might be instantly redeemable, but beer and wine are limited to cash back.
                    </div>
                </Body>

                <H2>Result</H2>
                <Body>
                    <div className='leading-8'>
                        <span className='block mb-10'>
                            Cash back promotions, powered by BYBE’s Retail API, funded by suppliers. The retailer took advantage of their loyalty program’s user base, and reduced legal risk by partnering with BYBE to power, validate, and payout consumers.
                        </span>
                        
                        <span className='block mb-10'>
                            Because of the retailer’s pragmatic legal approach to supplier-funded promotions, more suppliers participated. The retailer averaged 4x the number of monthly offers on their app and users had a consistent savings journey across most states and  the beer, wine and spirit categories. 
                        </span>
                        
                        <span className='block mb-10'>
                            The retailer amplified supplier promotions (available to users on their loyalty app) with the in-store signage and aligned their in-store displays with available promotions. Suppliers tracked and executed in-store execution through their distributor networks. The combination of digital promotions, a simplified customer redemption process, and in-store activation and alignment drove ROI 2.5x higher for suppliers. 
                        </span>
                        
                        <span className='block mb-10'>
                        Now, every supplier pitches the retailer with promotions, and leftover inventory for displayed items is greatly reduced as a result of higher sales in stores, highly attributed to promotional savings, averaging 25% of the sales value. 
                        </span>    
                    </div>
                    
                </Body>
            </div>

            <div>
                <H3>Products</H3>
                <ul>
                    <li>Dashboard</li>
                    <li>Retail API</li>
                    <li>Brand Site</li>
                    <li>Offer Directory</li>
                </ul>
            </div>
        </div> */}
    </div>

    <CallToAction title={'Ready to get started?'} 
        primarySubTitle={'Easily integrate BYBE into your current consumer experience'} 
        primaryButtonText={'Learn about our API'} 
        primaryButtonURL={'/products/#api'} 
        secondarySubTitle={'Discover where BYBE offers are available'}
        secondaryButtonText={'Active markets'} 
        secondaryButtonURL={'/company/#activeMarkets'}
    />
    </>;
}