import React, {useState} from 'react';


export default function Home(props){

  return <>
    <div className="flex items-center bg-gradient-to-br from-secondary-dark to-secondary py-10 px-4 md:px-0 space-y-2 min-h-screen-1/4 text-dark">
        <div className="md:mx-10 lg:mx-32 xl:mx-52 bg-white rounded shadow p-5 w-full space-y-7">
            
            <h1 className="uppercase text-3xl md:text-6xl text-primary font-secondary font-bold">
                Privacy Policy
            </h1>

            <div className="section">
                <p>
                    Welcome, and thank you for your interest in <a href="https://bybe.com" className="text-primary hover:text-primary-light tansition duration-300"><b>BYBE Inc.</b></a> (hereinafter referred to as "BYBE", "we", "us", or "our"). The following Privacy Policies are a legally binding contract between you and BYBE regarding your use of the BYBE website, mobile applications, networks, and other related features or services (collectively, the “Service”).
                </p>
            </div>
            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Introduction
                </h2>

                <p>
                    This Privacy Policy addresses how <span className="font-bold">BYBE Inc.</span> (“BYBE”) uses certain information provided by you in connection with certain purchases that you have registered on BYBE or through an affiliate retailer. Purchase information is submitted and managed through a separate experience fully hosted by BYBE.
                </p>

                <p>
                    BYBE respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes our practices for collecting, using, maintaining, protecting, and disclosing that information. This policy applies only to information we collect from the App or directly from you by email, text, web form, and other electronic communications. Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not provide us with information. By providing us with your information, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy).
                </p>
            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Age Restriction
                </h2>

                <p>
                    Our services are not intended for any person under 21 years of age, and we do not knowingly collect personal information from any person under 21. If we learn we have collected or received personal information from an individual under the age of 21, we will delete that information. If you believe we might have any information from or about an individual under the age of 21, please contact us at 
                    <a href="mailto:info@bybeapp.com" className="text-primary hover:text-primary-light transition duration-300">info@bybeapp.com</a>, and we will delete such information promptly.
                </p>
            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Information You Provide to Us
                </h2>
                <p>
                    When you respond to our emails, or enter information on the linked landing webpage, we may ask you provide information:
                </p>
                <ul>
                    <li>
                        By which you may be personally identified, such as name, postal address, email address, telephone number, age or any other identifier by which you may be contacted online or offline 
                        (“<span className="font-bold"> personal information</span>”).
                    </li>
                    <li>
                        That is about you individually but does not identify you, such as transaction-related information, such as when you make purchases, respond to any rebate offers, or download or use applications from BYBE.
                    </li>
                </ul>
            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    How We Use Your Information
                </h2>
                <p>
                    We use information that we collect about you or that you provide to us, including any personal information, to:
                </p>
                <ul>
                    <li>
                        Provide you with information, products or services that you request from us.
                    </li>
                    <li>
                        Fulfill and payout any related rebate offers in which you participate.
                    </li>
                    <li>
                        Fulfill any other purpose for which you provide it.
                    </li>
                    <li>
                        If you opt in to receive additional offers, we will email you when new offers are available.
                    </li>
                    <li>
                        We may report information back to affiliate retailer.
                    </li>
                </ul>
            </div>

            <div className="section" id="how-we-do-not-use-your-information">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    How We <u>Do Not Use</u> Your Information
                </h2>
                <p>
                    Subject to the above under How We Use Your Information the information we collect will not be used in any of the following ways:
                </p>
                <ul className="flex flex-col space-y-2 pt-2">
                    <li>
                        <b>Promotion by the Company.</b> We will not use your email address to promote or market third parties’ products or services.
                    </li>
                    <li>
                        <b>Targeted Advertising by the Company.</b> We will not use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences.
                    </li>
                    <li>
                        <b>Sale of Your Information to Third-Parties.</b> We will not sell information that we collect or that you provide to third-parties.
                    </li>
                    <li>
                        <b>Disclosure of Your Information for Third-Party Advertising and Marketing. </b>
                        We will not share your personal information with unaffiliated or non-agent third parties for advertising and marketing purposes. We will only share information with affiliated third parties for as long as necessary to fulfill rebate offers and as stated below under Disclosure of Your Information.
                    </li>
                </ul>
            </div>

            <div className="section" id="disclosure-of-your-information">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Disclosure of Your Information
                </h2>
                <p>
                    We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.
                </p>
                <p>
                    In addition, we may disclose personal information that we collect or you provide:
                </p>
                <ul>
                    <li>
                        To contractors, service providers, and other third parties we use to support our business (such as payment or gift card processors) and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
                    </li>
                    <li>
                        To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of BYBE Inc.'s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by BYBE Inc. about users is among the assets transferred.
                    </li>
                    <li>
                        For any other purpose disclosed by us when you provide the information.
                    </li>
                    <li>
                        With your consent.
                    </li>
                    <li>
                        To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
                    </li>
                    <li>
                        If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of BYBE Inc., our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                    </li>
                </ul>
            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Accessing and Correcting Your Personal Information
                </h2>
                <p>
                    You may send us an email at <a href="mailto:info@bybeapp.com" className="text-primary hover:text-primary-light transition duration-300">info@bybeapp.com</a> to request access to, correct, or delete any personal information that you have provided to us. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
                </p>
            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Your California Privacy Rights
                </h2>
                <p>
                    If you are a California resident, California law may provide you with additional rights regarding our use of your personal information. We adopt the below supplemental rights to comply with the California Consumer Privacy Act of 2018 (the “CCPA”) and any terms defined in the CCPA have the same meaning when used in this notice.
                </p>
                <p>
                    As stated in the&nbsp;<a href="#how-we-do-not-use-your-information" className="text-primary hover:text-primary-light transition duration-300">How We Do Not Use Your Data</a>
                    &nbsp;section, we do not
                    sell your personal data.
                </p>
            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Opt-Out Rights
                </h2>
                <p>
                    You can stop all collection of information by the App easily by uninstalling the App. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at&nbsp;
                    
                    <a href="mailto:info@bybeapp.com" className="text-primary hover:text-primary-light transition duration-300">info@bybeapp.com</a>.
                </p>
            </div>

            <div className="section">

                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Access to Specific Information and Data Portability Rights
                </h2>
                <p>
                    You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request by emailing <a href="mailto:info@bybeapp.com" className="text-primary hover:text-primary-light transition duration-300">info@bybeapp.com</a>, we will disclose to you:
                </p>
                <ul>
                    <li>
                        The categories of personal information we collected about you.
                    </li>
                    <li>
                        The categories of sources for the personal information we collected about you.
                    </li>
                    <li>
                        Our business or commercial purpose for collecting or selling that personal information.
                    </li>
                    <li>
                        The categories of third parties with whom we share that personal information.
                    </li>
                    <li>
                        The specific pieces of personal information we collected about you (also called a data portability request).
                    </li>
                    <li>
                        If we disclosed your personal information for a business purpose, a list disclosing the personal information categories that each category of recipient obtained.
                    </li>
                </ul>

            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Data Security
                </h2>
                <p>
                    We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions and personal information will be encrypted using SSL technology.
                </p>
                <p>
                    The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the App like message boards. The information you share in public areas may be viewed by any user of the App.

                </p>
                <p>
                    Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
                </p>
            </div>

            <div className="section" id="ChangestoOurPrivacyPolicy">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Changes to Our Privacy Policy
                </h2>
                <p>
                    We may update our privacy policy from time to time. If we make material changes to how we treat our users’ personal information, we may notify you by email or by informing you the next time you redeem an offer through BYBE.
                </p>
            </div>

            <div className="section">
                <h2 className="uppercase text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    Contact Information
                </h2>
                <p>
                    To ask questions or comment about this privacy policy and our privacy practices, contact us at: <br />                        
                    <b>BYBE INC</b><br />                        
                    PO Box 1621<br />                       
                    Columbus, OH 43216<br />                       
                    E-mail: <a href="mailto:info@bybeapp.com" className="text-primary hover:text-primary-light transition duration-300">info@BYBEapp.com</a>
                </p>
            </div>
        
        </div>
    </div>
  </>;
}