import React, {useEffect, useState} from 'react';
import {Link,  NavLink} from "react-router-dom";
import useNavigateGA from '../hooks/useNavigateGA';

export default function(){
    const [toggleNavMobileProducts, setToggleNavMobileProducts] = useState(false);
    const [toggleNavMobileCompany, setToggleNavMobileCompany] = useState(false);
    const [toggleNavMobileDevelopers, setToggleNavMobileDevelopers] = useState(false);
    const [activeSection, setActiveSection] = useState(false);
    const navigate = useNavigateGA();

    useEffect(() => {
        const menuBtn = document.querySelector(".menu-btn");
        let menuOpen = false;
        let flyoutMenu = document.querySelector("#flyoutMenu");

        function showMenu(e) {
            flyoutMenu.classList.add("show");
        }

        function hideMenu(e) {
            menuBtn.classList.remove("open");
            menuOpen = false;
            flyoutMenu.classList.remove("show");
        }

        hideMenu();

        menuBtn.addEventListener("click", () => {

            if (!menuOpen) {
                menuBtn.classList.add("open");
                menuOpen = true;
                showMenu();
            } else {
                menuBtn.classList.remove("open");
                menuOpen = false;
                hideMenu();
            }
        });

    }, [activeSection]);



    return <header className='text-dark-purple sticky top-0 z-50' style={{backgroundColor: "rgba(255, 255, 255, 0.95)",
        backdropFilter: "blur(10px)",
        backgroundColor: "hsla(0,0%,100%,.8)",
        opacity: "1"}}>

        <div className='container mx-auto flex justify-between'>

            <div className='logo ml-4 md:ml-0 md:my-2 flex items-center'>
                <NavLink to="/">
            <img src="/images/bybe-swiftly-logo.svg" alt="BYBE: Promotion platform for beer, wine, & spirits" className='w-40'/>
                </NavLink>
            </div>

            <nav className='hidden md:flex text-sm lg:text-base' style={{marginTop: "36px"}}>
                <div className='dropdown flex space-x-5 relative'>

                    <div className='nav-focus relative'>
                        <NavLink to="/products" activeClassName="active-link" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'products-header-click', gaEventLabel:'products-view'})}>Products</NavLink>

                        <div className='dropdown-menu absolute left-0 top-10 w-52 bg-white rounded-lg drop-shadow-lg flex flex-col'>
                            <Link to="/products" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'products-header-click-dashboard', gaEventLabel:'dashboard-view'})} className='py-5 px-10 hover:bg-gray-100 duration-500'>Dashboard</Link>
                            <Link to="/products/#api" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'products-header-click-api', gaEventLabel:'api-view'})} className='py-5 px-10 hover:bg-gray-100 duration-500'>API</Link>
                            <Link to="/products/#direct" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'products-header-click-direct', gaEventLabel:'bybe-direct-view'})} className='py-5 px-10 hover:bg-gray-100 duration-500'>Direct</Link>
                            <Link to="/products/#bybeApp" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'products-header-click-bybeapp', gaEventLabel:'bybeapp-view'})} className='py-5 px-10 hover:bg-gray-100 duration-500'>BYBE App</Link>
                            <Link to="/products/#engage" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'products-header-click-engage', gaEventLabel:'bybe-engage-view'})} className='py-5 px-10 hover:bg-gray-100 duration-500'>Engage</Link>
                            <a href="https://signs.bybe.io/" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'products-header-click-signs', gaEventLabel:'signs', newWindow:true})} className='py-5 px-10 hover:bg-gray-100 hover:rounded-b-lg duration-500'>Signs</a>
                        </div>
                    </div>

                    <div>
                        <NavLink to="/for-brands" activeClassName="font-bold" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'brands-header-click', gaEventLabel:'for-brands-view'})}>For Brands</NavLink>
                    </div>

                    <div>
                        <NavLink to="/for-retailers" activeClassName="font-bold" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'retailers-header-click', gaEventLabel:'for-retailers-view'})}>For Retailers</NavLink>
                    </div>

                    <div className='nav-focus relative'>
                        <NavLink to="/company" activeClassName="font-bold" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'company-header-click', gaEventLabel:'company-view'})}>Company</NavLink>

                        <div className='dropdown-menu bg-white rounded-lg drop-shadow-lg flex flex-col absolute left-0 top-10 w-52'>
                            <Link to="/company" className='py-5 px-10 hover:bg-gray-100 duration-500' onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'company-header-click-vision', gaEventLabel:'vision-view'})}>Vision</Link>
                            <Link to="/company/#activeMarkets" className='py-5 px-10 hover:bg-gray-100 duration-500' onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'company-header-click-active-markets', gaEventLabel:'active-markets-view'})}>Active markets</Link>
                            <Link to="/company/#partners" className='py-5 px-10 hover:bg-gray-100 duration-500' onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'company-header-click-partners', gaEventLabel:'partners-view'})}>Partners</Link>
                            <Link to="/company/#about" className='py-5 px-10 hover:bg-gray-100 duration-500' onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'company-header-click-about', gaEventLabel:'about-view'})}>About</Link>
                            <a href="https://www.linkedin.com/company/bybe-app/jobs/" className='py-5 px-10 hover:bg-gray-100 hover:rounded-b-lg duration-500' target="_blank" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'company-header-click-jobs', gaEventLabel:'jobs-view', newWindow:true})} rel="noreferrer">Jobs</a>
                        </div>
                    </div>

                    <div>
                        <NavLink to="/pricing" activeClassName="font-bold" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'pricing-header-click', gaEventLabel:'pricing-view'})}>Pricing</NavLink>
                    </div>

                    <div className='nav-focus relative'>
                        <NavLink to="/developers" activeClassName="font-bold" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'developers-header-click', gaEventLabel:'developers-view'})}>Developers</NavLink>

                        <div className='dropdown-menu bg-white rounded-lg drop-shadow-lg flex flex-col absolute left-0 top-10 w-52'>
                            <Link to="/developers" className='py-5 px-10 hover:bg-gray-100 duration-500' onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'developers-header-click-api', gaEventLabel:'api-view'})}>API</Link>
                            <Link to="/developers/#sftp" className='py-5 px-10 hover:bg-gray-100 hover:rounded-b-lg duration-500' onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'developers-header-click-sftp', gaEventLabel:'sftp-view'})}>SFTP integration</Link>
                        </div>
                    </div>
                </div>
            </nav>

            <div className='dropdown relative signIn hidden md:flex items-center text-sm lg:text-base'>
                <div className="nav-focus relative py-5">
                    <button className='flex items-center'>
                        <img className='h-[30px]' src="/images/icons/dashboard.svg" alt="" />
                        <span className='pt-1 font-bold'>Login</span>
                    </button>

                    <div className='dropdown-menu absolute right-0 top-16 w-52 bg-white rounded-lg drop-shadow-lg flex flex-col'>
                        <a href="https://dashboard.bybe.io/" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'brand-login', gaEventLabel:'dashboard-login', newWindow:true})} className='py-5 px-10 hover:bg-gray-100 duration-500'>Brand login</a>
                        <a href="https://dashboard.bybe.io/" onClick={event => navigate({event, gaEventCategory:'header-click', gaEventAction:'retailer-login', gaEventLabel:'dashboard-login', newWindow:true})} className='py-5 px-10 hover:bg-gray-100 hover:rounded-b-lg duration-500'>Retailer login</a>
                    </div>
                </div>
            </div>

            <div className="md:hidden flex items-center sticky mr-4">
                <div className="menu-btn relative flex justify-center items-center pr-1 cursor-pointer ">
                    <div className="menu-btn__burger"></div>
                </div>
            </div>

            <div id="flyoutMenu" className='block md:hidden p-4 bg-gray-100'>
                <div className='flex flex-col text-center space-y-5 text-3xl font-bold'>

                    <ul className='text-left'>
                        <li className={`show-hide-item ${toggleNavMobileProducts == true ? 'open' : '' }`} >
                            <div onClick={() => {setToggleNavMobileProducts(!toggleNavMobileProducts)}}>Products</div>

                            <div className='arrow'></div>

                            <div className={toggleNavMobileProducts ? 'show-hide-content text-sm font-normal' : 'hidden'}>
                                <Link to="/products" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-products-header-click-dashboard', gaEventLabel:'dashboard-view'})
                                }}
                                    className={"block py-5 px-10"}>Dashboard</Link>

                                <Link to="/products/#api" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-products-header-click-api', gaEventLabel:'api-view'})
                                }} className={"block py-5 px-10"}>API</Link>

                                <Link to="/products/#direct" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-products-header-click-direct', gaEventLabel:'bybe-direct-view'})
                                }} className={"block py-5 px-10"}>Direct</Link>

                                <Link to="/products/#bybeApp" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-products-header-click-bybeapp', gaEventLabel:'bybe-app-view'})
                                }} className={"block py-5 px-10"}>BYBE App</Link>

                                <Link to="/products/#engage" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-products-header-click-engage', gaEventLabel:'bybe-engage-view'})
                                }} className={"block py-5 px-10"}>Engage</Link>

                                <a href="https://signs.bybe.io/" onClick={event => {
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-products-header-click-signs', gaEventLabel:'signs-view', newWindow:true})
                                }} className={"block py-5 px-10"}>Signs</a>
                            </div>
                        </li>

                        <li className='show-hide-item'>
                            <Link to="/for-brands" onClick={event => {
                                setActiveSection(!activeSection)
                                navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-brands-header-click', gaEventLabel:'for-brands-view'})
                            }}>For Brands</Link>
                        </li>

                        <li className='show-hide-item'>
                            <Link to="/for-retailers" onClick={event => {
                                setActiveSection(!activeSection)
                                navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-retailers-header-click', gaEventLabel:'for-retailers-view'})
                            }}>For Retailers</Link>
                        </li>

                        <li className={`show-hide-item ${toggleNavMobileCompany == true ? 'open' : '' }`} >
                            <div onClick={() => {setToggleNavMobileCompany(!toggleNavMobileCompany)}}>Company</div>

                            <div className='arrow'></div>

                            <div className={toggleNavMobileCompany? 'show-hide-content text-sm font-normal' : 'hidden'}>
                                <Link to="/company" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-company-header-click-vision', gaEventLabel:'vision-view'})
                                }} className={"block py-5 px-10"}>Vision</Link>

                                <Link to="/company/#activeMarkets" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-company-header-click', gaEventLabel:'active-markets-view'})
                                }} className={"block py-5 px-10"}>Active Markets</Link>

                                <Link to="/company/#partners" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-company-header-click', gaEventLabel:'partners-view'})
                                }} className={"block py-5 px-10"}>Partners</Link>

                                <Link to="/company/#about" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-company-header-click', gaEventLabel:'about-view'})
                                }} className={"block py-5 px-10"}>About</Link>

                                <a href="https://www.linkedin.com/company/17903210/admin/" target="_blank" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-company-header-click', gaEventLabel:'jobs-view'})
                                }} className={"block py-5 px-10"} rel="noreferrer">Jobs</a>
                            </div>
                        </li>

                        <li className='show-hide-item'>
                            <Link to="/pricing" onClick={event => {
                                setActiveSection(!activeSection)
                                navigate({event, gaEventCategory:'mobile-header-click', gaEventAction:'pricing-header-click', gaEventLabel:'pricing-view'})
                            }}>Pricing</Link>
                        </li>

                        <li className={`show-hide-item ${toggleNavMobileDevelopers == true ? 'open' : '' }`}>
                            <div onClick={() => {setToggleNavMobileDevelopers(!toggleNavMobileDevelopers)}}>Developers</div>

                            <div className='arrow'></div>

                            <div className={toggleNavMobileDevelopers ? 'show-hide-content flex flex-col text-sm font-normal' : 'hidden'}>
                                <Link to="/developers" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-developers-header-click-api', gaEventLabel:'api-view'})
                                }} className={"block py-5 px-10"}>API</Link>

                                <Link to="/developers/#sftp" onClick={event => {
                                    setActiveSection(!activeSection)
                                    navigate({event, gaEventCategory:'m-header-click', gaEventAction:'m-developers-header-click-sftp', gaEventLabel:'sftp-view'})
                                }} className={"block py-5 px-10"}>SFTP integration</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>;
}