import React from 'react';
import useGoogleAnalytics4 from './hooks/useGoogleAnalytics4.jsx';
const Context = React.createContext();
export function Provider({children}) {
   
    const {gaEvent} = useGoogleAnalytics4('G-LG23VFCRDR');
    
    const value = {
        gaEvent
    }
    return <Context.Provider value={value}>
        {children}
    </Context.Provider>;
}
export default Context;