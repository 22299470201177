import React, {useState, useEffect, useRef} from 'react';

export default function GraphWrapper(props) {
    const graph = useRef();

    const [clientWidth, setClientWidth] = useState(720);
    const [clientHeight, setClientHeight] = useState(480);

    useEffect( () => {
        ( function() {
            const throttle = function(type, name, obj) {
                obj = obj || window;
                let running = false;

                const func = function() {
                    if (running) return;
                    running = true;
                    requestAnimationFrame( function() {
                        obj.dispatchEvent( new CustomEvent(name) );
                        running = false;
                    } );
                };

                obj.addEventListener(type, func);
            };

            throttle('resize', 'optimizedResize');
        } )();

        window.addEventListener( 'optimizedResize', () => {
            if (graph.current) setClientWidth(graph.current.clientWidth);
        } );

        setClientWidth(graph.current.clientWidth);
    }, [] );

    const childrenWithProps = React.Children.map(props.children, child => {
        return React.cloneElement( child, {
            width: clientWidth,
            height: clientHeight
        } );
    } );

    return <div ref={graph} className="lg:w-4/5 lg:mx-auto mb-10">
        {props.title ? <h2 className="py-2 text-dark text-2xl font-secondary font-bold uppercase">{props.title}</h2> : <></> }
        {childrenWithProps}
    </div>;
};
