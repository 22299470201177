import React from 'react';
import { Link } from 'react-router-dom';

import useNavigateGA from '../hooks/useNavigateGA.jsx';

import Subtitle from './headings/subtitle.jsx';
import Body from './texts/body.jsx';

export default function(){

    const navigate = useNavigateGA();

    return <footer className='py-16 md:py-24 bg-gray-200'>
        <div className="container md:px-0 px-4 mx-auto">
            <div className='md:grid grid-cols-3 grid-rows-2'>
                <div className='col-span-1 mb-10'>
                    <Subtitle purple>About</Subtitle>

                    <Body>BYBE is a digital promotion platform specializing in the beer, wine and spirits space. Our mission is to enable the adult beverage category to advance into the digital age while respecting regulatory compliance. Through radical transparency and working within the three-tiered system, we build products for the entire adult beverage ecosystem. BYBE manages promotions that simplify the consumer’s shopping and savings experience.</Body>

                    <div className='text-center md:text-left text-orange'>
                        <a href="mailto:BYBE.DEMO@bybeapp.com" onClick={ event  => navigate({event, gaEventCategory: 'footer-click', gaEventAction: 'footer-click-to-req-demo', newWindow: 'true'})} className='text-sm font-bold' target="_blank" rel="noreferrer">Request a demo</a>
                    </div>
                </div>

                <div className='col-span-2 justify-self-end'>
                    <div className='grid grid-cols-2 justify-between md:gap-x-16 md:w-[428px]'>
                        <div className='mb-10'>
                            <Subtitle purple>Products</Subtitle>

                            <ul className='space-y-3 text-sm'>
                                <li>
                                    <Link to="/products" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-dashboard'})}>Dashboard</Link>
                                </li>
                                <li>
                                    <Link to="/products/#api" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-documentation'})}>
                                        API
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/products/#direct" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-direct'})}>
                                        Direct
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/products/#bybeApp" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-bybeapp'})}>
                                        BYBE App
                                    </Link>

                                </li>
                                <li>
                                    <Link to="/products/#engage" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-engage'})}>
                                        Engage
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <div className='mb-10'>
                                <Subtitle purple>Follow Us</Subtitle>

                                <ul className='text-sm flex gap-x-2'>
                                    <li>
                                        <a href="https://www.facebook.com/BYBEapp" target="_blank" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-facebook', newWindow: 'true'})} rel="noreferrer">
                                            <img src="/images/facebook.svg" alt="Facebook" />
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://twitter.com/BYBEapp" target="_blank" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-twitter', newWindow: 'true'})} rel="noreferrer">
                                            <img src="/images/twitter.svg" alt="Twitter" />
                                        </a>
                                    </li>

                                    <li>
                                        <a href="https://www.instagram.com/bybeapp/" target="_blank" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-instagram', newWindow: 'true'})} rel="noreferrer">
                                            <img src="/images/instagram.svg" alt="Instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                             <div className='mb-10'>
                                <Subtitle purple>View offers</Subtitle>
                                <a href="https://bybeapp.com" target="_blank" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-view-offers--footer', gaEventLabel:'view-offers', newWindow: 'true'})} rel="noreferrer">
                                    <img alt='BYBE' className='inline w-[100px]' src="/images/bybeApp/bybeAppLogoPurple.svg" />
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='col-span-1 mb-10'>
                    <Subtitle purple>Operating in 40 markets</Subtitle>

                    <Link to="/company/#activeMarkets"><img src="/images/state-map-sm.png" alt="" className='w-3/4' /></Link>

                </div>

                <div className='col-span-2 mb-10 justify-self-center'>
            <a href="https://www.swiftly.com/" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-swiftly', newWindow: 'true'})} target="_blank" rel="noreferrer">
                    <img src='/images/Bybe-Swiftly-Logo.png' alt='Bybe Switftly Logo' width={200} height={200} />
                  </a>
                </div>
            </div>

            <div className='text-center space-y-2'>
                <div className="text-xs">
					<Link to="/terms-and-conditions.html" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-terms-and-conditions'})} className="py-3 hover:text-primary-dark transition duration-300">Terms & Conditions</Link>
                    <span className="px-3">|</span>
                    <Link to="/privacy-policy.html" onClick={event => navigate({event, gaEventCategory:'footer-click', gaEventAction:'click-to-privacy-policy'})} className="py-3 hover:text-primary-dark transition duration-300">Privacy Policy</Link>
				</div>

				<div className="text-xs">
					All rights reserved &copy;2022 BYBE, INC
				</div>
            </div>
        </div>
    </footer>;
}