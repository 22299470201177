import React, {useState, useEffect, useRef} from 'react';

import H1 from './headings/h1.jsx';
import Body from './texts/body.jsx';
import ButtonPrimary from './buttons/buttonPrimary.jsx';
import ButtonSecondary from './buttons/buttonSecondary.jsx';

export default function callToAction({title, primarySubTitle, primaryButtonText, primaryButtonURL, primaryButtonExternalLink, secondarySubTitle, secondaryButtonText, secondaryButtonURL}) {
    return <div className='bg-gray-100'>
        
        { secondaryButtonText ?
            <div className='container mx-auto py-16 md:py-24 px-4 md:px-0'>
                <H1 mobileCenter noBottomMargin>{title}</H1>
                
                <div className='twoColumn mt-5 md:mt-10 md:grid grid-flow-col grid-cols-2 grid-rows-2 md:gap-x-24 '>
                    <Body center>{primarySubTitle}</Body>

                    <span className='block mb-10 md:mb-0'>
                        {primaryButtonExternalLink ?
                            <ButtonPrimary url={primaryButtonURL} text={primaryButtonText} center lg newWindow _blank/>
                        :
                            <ButtonPrimary url={primaryButtonURL} text={primaryButtonText} center lg reactLink />
                        }
                    </span>
                    
                    <Body center>{secondarySubTitle}</Body>
                    
                    <ButtonSecondary url={secondaryButtonURL} text={secondaryButtonText} center lg />
                </div>
            </div>
        :
            <div className='container mx-auto py-16 md:py-24 px-4 md:px-0'>
                <H1 mobileCenter>{title}</H1>
                <ButtonPrimary url={primaryButtonURL} text={primaryButtonText} center lg newWindow _blank/>
            </div>
        }
    </div>
}