import React, {useEffect} from 'react';

import lottie from 'lottie-web';

import CallToAction from './components/callToAction.jsx';

import Title from './components/headings/title.jsx'
import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import ButtonSecondary from './components/buttons/buttonSecondary.jsx';
import H1 from './components/headings/h1.jsx'
import H2 from './components/headings/h2.jsx'
import H3 from './components/headings/h3.jsx'
import H4 from './components/headings/h4.jsx'
import Description from './components/texts/description.jsx'
import Body from './components/texts/body.jsx'
import useNavigateGA from './hooks/useNavigateGA.jsx';

import lottieDashboardJson from '../src/js/animations/lottieDashboard.json';
import lottieAPIJson from '../src/js/animations/lottieAPI.json';
import lottieDirectJson from '../src/js/animations/lottieDirect.json';
import lottieAppJson from '../src/js/animations/lottieApp.json';
import lottieEngageJson from '../src/js/animations/lottieEngage.json';
import lottieRecapsJson from '../src/js/animations/lottieRecaps.json';
import lottieFeaturesJson from '../src/js/animations/lottieFeatures.json';

export default function Pricing(){

    let lottieDashboardContainer = React.createRef();
    let lottieAPIContainer = React.createRef();
    let lottieDirectContainer = React.createRef();
    let lottieAppContainer = React.createRef();
    let lottieEngageContainer = React.createRef();
    let lottieRecapsContainer = React.createRef();
    let lottieFeaturesContainer = React.createRef();

    useEffect(() => {
        lottie.loadAnimation({
          container: lottieDashboardContainer.current,
          animationData: lottieDashboardJson
        })
        
        lottie.loadAnimation({
          container: lottieAPIContainer.current,
          animationData: lottieAPIJson
        })
        
        lottie.loadAnimation({
          container: lottieDirectContainer.current,
          animationData: lottieDirectJson
        })
        
        lottie.loadAnimation({
          container: lottieAppContainer.current,
          animationData: lottieAppJson
        })
        
        lottie.loadAnimation({
          container: lottieEngageContainer.current,
          animationData: lottieEngageJson
        })
        
        lottie.loadAnimation({
          container: lottieRecapsContainer.current,
          animationData: lottieRecapsJson
        })
        
        lottie.loadAnimation({
          container: lottieFeaturesContainer.current,
          animationData: lottieFeaturesJson
        })
      }, []);

    return <>
        <div className='container mx-auto px-4 md:px-0 mt-8 md:mt-16'>
            <Title center>Straight-forward,<br /> transparent pricing.</Title>
        </div>

        <div className='gradient-darker'>
            <div className='container mx-auto px-4 md:px-0 '>
                <div className='mb-16 md:mb-24 py-16 md:p-24 text-center rounded-lg'>

                    <h2 className={'mb-16 md:mb-10 text-2xl md:text-3xl tracking-[-0.05em] text-[#e1deb1]'}>Access BYBE’s platform in distribution <span className='md:inline-block'>with simple <span className='inline-block'>pay-as-you-go pricing</span></span></h2>
                    
                    <div className='my-5 md:my-10'>
                        <div className='flex items-center justify-center '>
                            <img src="/images/25percent.png" className='w-3/4 h-3/4 md:w-auto md:h-auto' alt="25%" />
                            <div className='text-xl md:text-3xl inline-block font-bold text-light-blue'>+25¢</div>    
                        </div>
                        <p className='text-light-blue mb-5 md:mb-10 text-lg font-bold'>Per successful transaction</p>
                    </div>
                    
                    <div className='mx-auto w-96 h-96' ref={lottieFeaturesContainer}></div>

                    <ul className='2xl:w-3/4 inline-block mx-auto md:grid grid-cols-3 gap-x-4 md:gap-x-0 mb-5 md:mb-10 text-left md:text-center list-disc md:list-none marker:text-orange'>
                        <li className='mb-5 text-md md:text-xl tracking-[-0.05em] text-white'>Capped at $1.75 per transaction</li>
                        <li className='mb-5 text-md md:text-xl tracking-[-0.05em] text-white'>Access to  BYBE's dashboard</li>
                        <li className='mb-5 text-md md:text-xl tracking-[-0.05em] text-white'>No setup, impression or “clip” fees</li>
                    </ul>

                    <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'pricing-page-click'} gaEventAction={'click-to-req-demo'} newWindow text="Get started" center lg _blank darkBG/>    
                </div>
            </div>
        </div>
      
        <div className='container mx-auto px-4 md:px-0 mt-16'>
            <div>
                <H1>What's included</H1>
                
                <div className='mb-16 md:mb-24 md:grid grid-cols-3 md:gap-10'>
                    <div className='mb-16 md:mb-0'>
                        
                        <div className='flex items-center'>
                            <div className='mb-5 w-[65px] h-[65px] float-left inline mr-5' ref={lottieDashboardContainer} />

                            <H2 inline>Dashboard</H2>
                        </div>
                        
                        
                        <ul>
                            <li> <Checkmark />
                                Easily submit and manage offers
                            </li>

                            <li> <Checkmark />
                                Real-time data analysis
                            </li>
                            
                            <li> <Checkmark />
                                Clearing and reconciliation
                            </li>
                        
                            <li> <Checkmark />
                                Fraud prevention
                            </li>
                            
                            <li> <Checkmark />
                                Historical redemption information
                            </li>
                        </ul>
                    </div>

                    <div className='mb-16 md:mb-0'>
                        <div className="flex items-center">
                            <div className='mb-5 w-[65px] h-[65px] mr-5' ref={lottieAPIContainer} />

                            <H2 inline>API</H2>
                        </div>

                        <ul>
                            <li> <Checkmark />
                                State-by-state compliance
                            </li>

                            <li> <Checkmark />
                                Embedded user experience
                            </li>
                            
                            <li> <Checkmark />
                                Utilize BYBE Network with millions of users
                            </li>
                        
                            <li> <Checkmark />
                                Easy integration
                            </li>
                            
                            <li> <Checkmark />
                                Best in class post-purchase remittance flow
                            </li>
                        </ul>
                    </div>

                    <div className='mb-16 md:mb-0'>
                        <div className="flex items-center">
                            <div className='mb-5 w-[65px] h-[65px] mr-5' ref={lottieDirectContainer} />
                            
                            <H2 inline>Direct</H2>
                        </div>
                        
                        <ul>
                            <li> <Checkmark />
                                Include promotions in DTC strategy
                            </li>

                            <li> <Checkmark />
                                Embedded user experience on brand site
                            </li>
                            
                            <li> <Checkmark />
                                Customizable branding elements
                            </li>
                        
                            <li> <Checkmark />
                                Easy integration
                            </li>
                            
                            <li> <Checkmark />
                                Seamless customer journey
                            </li>
                        </ul>
                    </div>

                    <div className='mb-16 md:mb-0'>
                        <div className="flex items-center">
                            <div className='mb-5 w-[65px] h-[65px] mr-5' ref={lottieAppContainer} />
                            
                            <H2 inline>BYBE App</H2>
                        </div>

                        <ul>
                            <li> <Checkmark />
                                Easiest way to execute a digital promo
                            </li>

                            <li> <Checkmark />
                                Cash back offers within 48 hours
                            </li>
                            
                            <li> <Checkmark />
                                Utilize the BYBE app user base
                            </li>
                        
                            <li> <Checkmark />
                                Market offers digitally or in-store
                            </li>
                            
                            <li> <Checkmark />
                                Ensure offers meet rest of market requirements
                            </li>
                        </ul>
                    </div>

                    <div className='mb-16 md:mb-0'>
                        <div className="flex items-center">
                            <div className='mb-5 w-[65px] h-[65px] mr-5' ref={lottieEngageContainer} />

                            <H2 inline>Engage</H2>
                        </div>
                        
                        <ul>
                            <li> <Checkmark />
                                Leverage BYBE’s DTC marketing
                            </li>

                            <li> <Checkmark />
                                Drive traffic to offers through social media
                            </li>
                            
                            <li> <Checkmark />
                                Utilize influencer marketing
                            </li>
                        
                            <li> <Checkmark />
                                Tap into BYBE Engage followers
                            </li>
                            
                            <li> <Checkmark />
                                Tie your digital marketing to sales
                            </li>
                        </ul>
                    </div>

                    <div className='mb-16 md:mb-0'>
                        <div className="flex items-center">
                            <div className='mb-5 w-[65px] h-[65px] mr-5' ref={lottieRecapsContainer} />
                            
                            <H2>Offer recaps</H2>
                        </div>

                        <ul>
                            <li> <Checkmark />
                                Easily digestible redemption data
                            </li>

                            <li> <Checkmark />
                                State-by-state breakdown
                            </li>
                            
                            <li> <Checkmark />
                                Retailer and store level summary
                            </li>
                        
                            <li> <Checkmark />
                                Direct, transparent final program costs
                            </li>
                            
                            <li> <Checkmark />
                                Delivered to you at no cost
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <CallToAction title={'Ready to get started?'} 
            primarySubTitle={'Contact our sales team'} 
            primaryButtonText={'Reach out'}
            primaryButtonExternalLink 
            primaryButtonURL={'mailto:BYBE.DEMO@bybeapp.com'} 
            secondarySubTitle={'Discover where BYBE offers are available'}
            secondaryButtonText={'Active markets'} 
            secondaryButtonURL={'/company/#activeMarkets'}
        />
    </>;
}

function Checkmark() {
    return <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-3 text-orange" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
    </svg>;
}