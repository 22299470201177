import React from 'react';

import CallToAction from './components/callToAction.jsx';
import Subtitle from './components/headings/subtitle.jsx'
import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import ButtonSecondary from './components/buttons/buttonSecondary.jsx';
import H1 from './components/headings/h1.jsx'
import H2 from './components/headings/h2.jsx'
import Description from './components/texts/description.jsx'
import Body from './components/texts/body.jsx'
import useNavigateGA from './hooks/useNavigateGA.jsx';

export default function ForBrands({}){

    const navigate = useNavigateGA();

    return <>
        <div className='mesh-gradient-4'>
            <div className='container mx-auto px-4 md:px-0 pt-16 md:pt-24'>
                <div className='pb-16 md:pb-24'>
                    <H1 light>Eliminate “we have to check with legal”</H1>
                    
                    <span className='block w-full md:w-2/3'>
                        <Subtitle lightYellow>Tools for adult beverage suppliers, by industry veterans. We are the go-to platform for navigating regulatory constraints, and omnichannel marketing challenges.</Subtitle>    
                    </span>
                    
                    <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'for-brands-page-click'} gaEventAction={'click-to-req-demo'} newWindow text="Schedule demo" lg mobileCenter _blank darkBG/>
                </div>
            </div>
        </div>

        <div className='container mx-auto px-4 md:px-0 pt-16 md:pt-24'>
            <div className='md:grid twoColumn grid-cols-2 grid-rows-2 gap-10 mb-16 md:mb-24  '>
                <div className='text-center'>
                    <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/bullhorn.svg" />
                    <H2>A one-stop shop for promotions</H2>
                    
                    <p className='mb-5 text-sm md:text-base'>Access BYBE’s dashboard for promotion planning, budget tools and manage distribution on BYBE’s retail and affiliate network.</p>
                </div>

                <div className='text-center'>
                    <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/cogs.svg" />
                    <H2>Embedded promotions</H2>
                    <p className='mb-5 text-sm md:text-base'>Distribute offers while BYBE validates transaction validations, pays out consumers and facilitates reconciliation.</p>
                </div>

                <div className='text-center'>
                    <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/account-manager.svg" />
                    <H2>A dedicated account manager</H2>
                    <p className='mb-5 md:mb-0 text-sm md:text-base'>Simplify promotion setup support, data analysis, and promotion optimization with a team exclusively working with adult beverage brands.</p>
                </div>

                <div className='text-center'>
                    <img className='mx-auto mb-5 w-[50px] h-[50px]' src="/images/icons/lightbulb.svg" />
                    <H2>Leverage BYBE’s regulatory know-how</H2>
                    <p className='mb-5 md:mb-0 text-sm md:text-base'>Align with the go-to Adult Beverage promotion platform. From compliance, customer service, and execution.</p> 
                </div>
            </div>

            <div className='mb-16 md:mb-24'>
                <div className=''>
                    <H2 center>Stay current with offer performance</H2>

                    <Body center>View real-time redemptions by day, along with costs, clips, impressions and other key metrics.<br /> Historical offer performance is available at any time.</Body>
                </div>

                <div className='relative z-10'>
                    <video style={{width: "78%", marginTop: "1.5%"}} className='max-h-full absolute top-0 left-0 right-0 mx-auto rounded-t-xl z-10' playsInline autoPlay muted loop><source src="/videos/offer-performance.mp4" type="video/mp4" />Your browser does not support the video tag.</video>
                    <img src="/images/mbp.svg" />    
                </div>  
            </div> 

            <div className='mb-16 md:mb-24 md:w-2/3'>
                <H2>Offer breakdowns</H2>

                <Body>Get granular with filters, breaking down data by: product, retailer, markets, and user types for active and historical offers. </Body>
            
            
                <div className='relative z-10 mb-10 drop-shadow-lg'>
                    <img src="/images/browser-top2.png" alt="" />

                    <video className='max-h-full' playsInline autoPlay muted loop><source src="/videos/offer-breakdowns.mp4" type="video/mp4" />Your browser does not support the video tag.</video>  
                </div>
            </div>

            <div className='md:flex mb-16 md:mb-24 md:space-x-24'>
                <div className='mb-24 md:mb-16'>
                    <div>
                        <H2>Offer recaps</H2>
                        
                        <Description>Receive automated recaps when an offer ends, delivered directly to your inbox. The summary includes final cost, daily redemptions, interactions, and performance snapshots by retailer and states.</Description>
                        
                        <img src='/images/browser-top-offer-recaps.png' className='drop-shadow-lg' />
                        <img src='/images/screenshots/offer-recaps.png' className='drop-shadow-lg' />
                    </div>    
                </div>
            
                <div>
                    <div className='mb-24 md:mb-16'>
                        <H2>Keep track of your budgets</H2>
                        
                        <Description>Direct visibility to offer costs in live time. Cost is broken down by day, offer , retailer, and state. Accessible in the same dashboard you create and view offer performance. No third-party clearinghouse required.</Description>
                        
                        <img src='/images/browser-top-budgets.png' className='drop-shadow-lg' />
                        <img src='/images/screenshots/cost-breakdown.png' className='drop-shadow-lg'/>
                    </div>

                    
                    <H2>Share with your team</H2>
                    
                    <Description>Easily export redemption data in digestible formats.</Description>
                    <img src='/images/browser-top-share.png' className='drop-shadow-lg' />
                    <img src='/images/screenshots/data.png' className='drop-shadow-lg'/>
                    
                </div> 
            </div>

            <div className='mb-16 md:mb-24 md:w-2/3'>
                <H2>Gain insight on redemption location</H2>

                <Body>Quick view of redemptions by market.</Body>
            
                <div className='relative z-10 mb-10 drop-shadow-lg'>
                    <img src='/images/browser-top-location.png' className='drop-shadow-lg' />
                    <video className='max-h-full' playsInline autoPlay muted loop><source src="/videos/redemptions-by-state-store.mp4" type="video/mp4" />Your browser does not support the video tag.</video>
                </div>
            </div>

            <div className='mb-16 md:mb-24'>
                <H2 className='mb-28 md:mb-48'>Best performing days at a glance</H2>
                
                <Description>View offer trends by daily redemptions.</Description>
                
                <img src='/images/browser-top-days.png' className='drop-shadow-lg' />
                <img src='/images/screenshots/redemptions-day.png' className='drop-shadow-lg mb-10'/>
            </div>

            {/* <div className='mb-24 md:mb-16'>
                <H1>Case Study</H1>
            </div>

            <div className='md:flex mb-28 md:mb-48 md:space-x-24'>
                <div className='md:w-1/2'>    
                    <H2>Problem</H2>
                    
                    <Description>
                        A major beer brand wanted to digitize their coupon and rebate strategy, but needed a partner to navigate tied house and state-by-state promotion laws.
                    </Description>

                    <Body>
                        <span className='block leading-6'>
                            A major beer brand consistently invested in an aggressive coupon and rebate strategy, and believed savings drove incremental sales, trial and a path to gain market share. Major retail customers were frustrated how these coupons were not available in key beer markets, specifically restricted by processing of promotional budgets and rest of market requirements. 

                            The beer brand leveraged coupons and rebates as a tool to sell-in national display plans to retailers, but most major retailers were not able to promote any digital platform where the customer journey and user data didn’t live directly within the retailer’s mobile app. 

                            Unfortunately for the beer brand, they aren’t allowed to provide anything of value to the retailer, all their offers are legally required to be available to every consumer and retailer, and the retailer cannot process their promotional funds. 

                            They struggled with a compliant solution to sell in displays, drive sales, and enable their offers to live within their key retailers’ loyalty apps and websites.  
                        </span>
                        
                    </Body>

                    <H2>Solution</H2>
                    
                    <Description>
                        The beer brand leveraged our platform to connect their brand to their customers on retail apps, the BYBE app, and brought their digital promotions to their brand site in lieu of a one-off microsites for each promotion.
                    </Description>

                    <Body>
                        <span className='block leading-6'>
                            The beer brand initially partnered with BYBE as a way to distribute their digital offers to key retailers. Their National Account team aligned BYBE offers (available to retail loyalty users) with their in-store displays. The customer journey was consistent with the key retail’s existing digital coupon flow, with the exception that all promotions were cash back. BYBE’s Retail API, validated and paid out consumers via the post-purchase flow feature, and the brand tracked redemptions in real-time on the BYBE dashboard. 

                            The brand realized how critical in-store execution was to driving sales and redemptions. The brand used BYBE dashboard’s sign template and promotion preview features to empower their distributor reps to print and activate promotions in store. Their display and in-store sign execution increased 30%. 

                            After satisfying their retail partners desire to access offers in a compliant process, the brand sought BYBE as a compliance partner, ensuring their offer was available to any retailer and all consumers, a requirement for rest of market coverage. The retailer leveraged BYBE app redemption data to identify which retail user bases redeem offers, and if the retailer had a loyalty program, they recommended using BYBE’s Retail API and make the experience more immersive. 

                            During the pandemic, the beer brand recognized the importance of establishing a direct relationship with consumers. They invested heavily on their brand websites, buy now and find in-store sections on their brand sites, and social media advertising and content strategies. The brand determined they should include promotions wherever consumers seek product information and buy their product. 

                            They used BYBE’s offer directories to highlight savings opportunities for users. And they added their existing digital promotions directly to their brand sites with BYBE Brand sites. The beer brand wants to add value and make redemptions available no matter which platform the consumer is engaging their brand with.
                        </span>
                    </Body>    
                </div>

                <div>
                    <H3>Products</H3>
                    <ul> 
                        <li>Dashboard</li>
                        <li>Retail API</li>
                        <li>Brand Site</li>
                        <li>Offer Directory</li>
                    </ul>
                </div>
            </div> */}
        </div>

        <CallToAction title={'Ready to get started?'} 
            primarySubTitle={'Pay for what matters: redemptions. View pay-per-performance pricing.'} 
            primaryButtonText={'Pricing'} 
            primaryButtonURL={'/pricing'} 
            secondarySubTitle={'Discover where BYBE offers are available'}
            secondaryButtonText={'Active markets'} 
            secondaryButtonURL={'/company/#activeMarkets'}
        />
    </>;
}