import React, {useState} from 'react';


export default function Home(props){

  return <>
    <div className="flex items-center bg-gradient-to-br from-secondary-dark to-secondary py-10 px-4 md:px-0 space-y-2 min-h-screen-1/4 text-dark">
        <div className="md:mx-10 lg:mx-32 xl:mx-52 bg-white rounded shadow p-5 w-full space-y-7">
            <h1 className="text-3xl md:text-6xl text-primary font-secondary font-bold">
                TERMS & CONDITIONS
            </h1>
    
            <div className="section">
                <p>
                    Welcome, and thank you for your interest in <a href="https://bybe.com" className="text-primary hover:text-primary-light tansition duration-300"><b>BYBE Inc.</b></a> (hereinafter referred to as "BYBE", "we", "us", or "our"). The following Terms of Use are a legally binding contract between you and BYBE regarding your use of the BYBE website, mobile applications, networks, and other related features or services (collectively, the “Service”).
                </p>
                <p className="font-bold">
                    IMPORTANT:  This Agreement includes resolution of disputes by arbitration instead of in court.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    INTRODUCTION
                </h2>
    
                <p>
                    These terms detail how you may earn and redeem rebates in connection with alcoholic beverage purchases that you have made and registered through the BYBE app (the “App”). By registering and processing rebates through the App, you explicitly acknowledge that you have read through and agree to these terms.
                </p>
            </div>
    
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    HOW IS YOUR DATA USED?
                </h2>
                <p>
                    BYBE collects data about your purchase transaction from the receipt submission so that BYBE can validate your compliance with all rebate rules and process the payment of your rebate. We also share transaction data with relevant partners about the rebates earned through the App. The sharing of your data is governed by BYBE’s privacy policy (accessible at 
                    <a href="https://bybe.com/privacy-policy.html" title="Privacy Policy" className="text-primary hover:text-primary-light transition duration-300">Privacy Policy</a>), which is made available through the App or BYBEapp.com.
                </p>
            </div>
    
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    ELIGIBILITY
                </h2>
    
                <p>
                    You must be at least twenty-one (21) years old to participate in the BYBE rebate program service. In order to qualify for rebates administered by BYBE, you must comply with all rebate program rules, as set by the alcohol manufacturer that is sponsoring the rebate. This includes restrictions such as where the purchase was made, and household limitations on the numbers of rebates that may be redeemed.
                </p>
            </div>
    
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    PROCESSING OF REBATES
                </h2>
                <p>
                    All alcoholic rebate offers processed through this service are processed by, and paid to you, by BYBE. Each time you redeem a rebate offer, once BYBE determines that you are eligible for the rebate, BYBE will, upon request, distribute your rebate via one or more available payout options, if you are in good standing at the time of your request. You may also accumulate your rebates in your BYBE account (“Account”) for payout at a later time. All accumulated rebates will be subject to Account service fees as described below.
                </p>
            </div>
    
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    USER REWARDS
                </h2>
            </div>
    
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    A.  USER REWARDS GENERALLY
                </h2>
                <p>
                    BYBE makes available through the Service offers on certain third-party products and services (each an "offer"). When you redeem an offer through the Service, you will accumulate rewards in your Account (“Rewards”). Each offer may be subject to Additional Terms and may be discontinued by BYBE at any time. At any time and in BYBE’s sole discretion, BYBE may: (i) determine whether or not you are eligible to redeem an offer; (ii) determine whether or not you have earned Rewards and are entitled to redeem or distribute Rewards; or (iii) adjust your Rewards total, including but not limited to through Account service or other fees.  BYBE will charge an inactivity fee against any balance in your Account equal to $0.50 per month beginning on the date that is one hundred twenty (120) days after your last transaction or login to your Account; provided, that BYBE may adjust the amount of such fee from time to time by amending this Agreement.  PRIOR TO DISTRIBUTION, AS DESCRIBED BELOW, ALL REWARDS SHALL REMAIN THE SOLE PROPERTY OF BYBE.
                </p>
            </div>
            
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    B.  DISTRIBUTIONS
    
                </h2>
                <p>
                    You may elect to have BYBE distribute your Rewards via one or more of the available payout options as shown in the BYBE mobile app as long as you are in good standing with BYBE at the time of the election. To be in good standing, you must have earned a Reward within the last one hundred twenty (120) days. If you are no longer in good standing at the time of your election to distribute a Reward, you must first return to good standing by earning another Reward, and then you may proceed with your election to distribute a Reward. If you elect to close your BYBE account, you will be expected to distribute such Rewards prior to closing your account. If you fail to properly distribute your Rewards when closing your account, or if your account is terminated by BYBE as described below, any rights you have to the Rewards in your account will terminate and you will no longer be eligible to receive a distribution of such Rewards.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    C.  REFERRAL BONUSES
                </h2>
                <p>
                    From time to time, BYBE may introduce special bonuses or incentives for users to refer others to BYBE, using a personal referral code or URL (“Referral Bonuses”). Users shall not be eligible to earn these bonuses if they invite other users who share the same mobile device or if they create multiple accounts in an effort to earn referral bonuses for inviting themselves. Such actions violate these Terms of Use and shall be grounds for BYBE to terminate a User’s account, rescind any referral bonuses, reverse or correct user Rewards balances, or take other actions as appropriate. Where a user cannot produce examples of how referrals have taken place, including screenshots of where referrals were made, BYBE reserves the right to decline to award Referral Bonuses, rescind bonuses, demand repayment of Rewards credited, or take other remedial actions. Referral Bonuses, once earned, are credited to your Account and can be distributed to the same extent as Rewards.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    D. TAXES
                </h2>
                <p>
                    Rewards may be subject to taxes. You will be solely responsible for any and all tax liability arising out of your receipt of Rewards.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    E.  COMPLIANCE WITH LAW
                </h2>
                <p>
                    Under state escheatment law, or other applicable law, BYBE may be required to remit Rewards in your BYBE account if your BYBE account has not had any activity for a certain period of time. If we remit funds as required by law, you will need to contact the state directly to obtain the funds.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    F.  CLAIMS
                </h2>
                <p>
                    Rewards provided under USER REWARDS are provided solely by BYBE and not underwritten, sponsored, or provided by any other third-party, including BYBE's brand and retail partners. By accepting these Terms of Use you agree not to seek Rewards or make any claims for Rewards against third-party providers, retailers or manufacturers of products and services.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    PROHIBITED CONDUCT
                </h2>
                <div>
                    BYBE may, in its sole discretion, block, suspend, or refuse to fulfill any rebate submission that BYBE deems to be fraudulent or abusive of the system. Accordingly, you are prohibited from doing any of the following:
                    <ol type="A">
                        <li>A.	Redeeming, or attempt to redeem, rebate offers for products that have not actually been purchased, or for products that have been purchased but returned to a store or online vendor, following purchase;</li>
                        <li>B.	Redeeming, or attempt to redeem rebate offers that do not comply with the rebate program rules set by the rebate sponsor;</li>
                        <li>C.   Submit purchase receipts to BYBE that have already been uploaded by you or another individual;</li>
                        <li>D.   Reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the BYBE services;</li>
                        <li>E.	Intentionally interfere with or damage operation of the BYBE service by any means, including uploading or otherwise disseminating viruses, adware, spyware, worms, or other malicious code;</li>
                        <li>F.	Using the BYBE service for any purpose that violates any law.</li>
                    </ol>
                   
                </div>
                <p>
                    If you engage in any of the prohibited conduct listed above or otherwise violate any of these terms, your permission to use the BYBE services may automatically be terminated. BYBE may suspend or revoke your access to and use of the BYBE services, if necessary, to comply with legal requirements or to protect the rights or interests of BYBE or any third party.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    ACCOUNTS AND REGISTRATION
                </h2>
                <p>
                    To access most features of the Service you must register for an Account. When you register for an Account, you may be required to provide us with some information about yourself, including your name and email address. You agree that the information you provide to us is and will be accurate and up to date at all times. When you register, you will be asked to provide a password. You are solely responsible for maintaining the confidentiality of your Account and password. You agree to accept responsibility for all activities that occur under your Account. If you have reason to believe that your Account is no longer secure for any reason (for example, in the event of a loss, theft or unauthorized disclosure or use of your password), then you agree to notify us immediately at
                    <a href="mailto:info@bybeapp.com" className="text-primary hover:text-primary-light transition duration-300">info@bybeapp.com</a>.
                </p>
                <p>
                    A user may not create more than one (1) BYBE Account. Each unique mobile device may not be associated with more than one (1) user Account. Users who attempt to associate an excessive number of mobile devices with a single user Account may be deemed to have violated these Terms of Use to the extent they are deemed by BYBE to have abused the Service. Additional verification for Account may be asked upon redemption.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    INTELLECTUAL PROPERTY RIGHTS
                </h2>
                <p>
                    All visual interfaces, graphics, design, compilation, information, computer code (including source code or object code), products, software, services, and all other elements of the services provided by BYBE are protected by United States copyright, trade dress, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws and are the property of BYBE or its subsidiaries or affiliated companies and/or third-party licensors. All trademarks, service marks, and trade names are proprietary to BYBE or its affiliates and/or third-party licensors.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    DISCLAIMERS; NO WARRANTIES
                </h2>
                <p>
                    THE SERVICE IS PROVIDED “AS IS'' AND ON AN “AS AVAILABLE” BASIS, WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. BYBE SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIMS (I) ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT; OR (II) ANY WARRANTIES ARISING OUT OF COURSE-OF-DEALING, USAGE, OR TRADE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES IN SOME CIRCUMSTANCES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    LIMITATION OF LIABILITY
                </h2>
                <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER BYBE NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OPERATING OR DELIVERING THE SERVICE WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL THEORY, WHETHER OR NOT BYBE HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE WHERE SUCH DAMAGES RESULT FROM YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE SERVICE. YOU AGREE THAT THE AGGREGATE LIABILITY OF BYBE TO YOU FOR ANY AND ALL CLAIMS ARISING FROM OR RELATED TO THE FACE VALUE OF ALL REWARDS PROCESSED ON ELIGIBLE OFFERS THROUGH THE SERVICE IN THE 12 MONTHS PRECEDING SUCH CLAIM. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN BYBE AND YOU.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    GOVERNING LAW
                </h2>
                <p>
                    This rebate program, and these terms, are governed by the laws of the State of Ohio. You agree that any legal claim that you have in connection with these terms and the rebate will be brought exclusively in the courts located in Franklin County, Ohio, and you submit to the jurisdiction of those courts.
                </p>
    
                <ul>
                    <li>
                        <h2 className="text-xl md:text-3xl text-secondary font-secondary font-bold">
                            MANDATORY BINDING INDIVIDUAL ARBITRATION
                        </h2>

                        <p className="font-bold">
                            Please read this section carefully.  It affects legal rights that you may otherwise have and requires individual final and binding arbitration of most disputes instead of resolution in court.
                        </p>

                        <p>
                            Arbitration is the submission of a dispute to a neutral arbitrator, instead of a judge or jury, for a final and binding decision, known as an “award.”  Arbitration provides for more limited discovery than in court, is subject to limited review by courts, and the result is confidential.  Each party has an opportunity to present evidence to the arbitrator in writing or through witnesses.  An arbitrator can only award the same damages and relief that a court can award under the law and must honor the terms and conditions in this Agreement.
                        </p>
                    </li>
    
                    <li>
                        <h2 className="text-xl md:text-3xl text-secondary font-secondary font-bold">
                            ARBITRATION AGREEMENT, CLASS ACTION WAIVER, PUNITIVE DAMAGES WAIVER
                        </h2>

                        <p>
                            You and BYBE agree that any dispute, whether at law or equity, arising out of or relating to this Agreement or your use of the Service (including the Rewards program), regardless of the date of accrual of such dispute, including but not limited to the arbitrability of any dispute, shall be resolved in its entirety by individual (not class-wide or collective) binding arbitration, except that you or BYBE may take claims to small claims court if they qualify for hearing by such a court.
                        </p>
    
                        <p>
                            You and BYBE agree that any arbitration under this Agreement will take place on an individual basis and that class, mass, consolidated or combined actions or arbitrations or proceedings as a private attorney general are not permitted. You and BYBE agree that the arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim. 
                        </p>
    
                        <p>
                            You and BYBE both waive, to the fullest extent allowed by law, any claims to recover punitive or exemplary damages.  This agreement to arbitrate extends to claims that you assert against other parties, including without limit claims against BYBE’s affiliates and related entities.
                        </p>
                        
                        <p>
                            The Agreement evidences a transaction in interstate commerce and the Federal Arbitration Act governs the interpretation and enforcement of this agreement to arbitrate.
                        </p>
    
                        <p>
                            This agreement to arbitrate shall survive termination of this Agreement.  If it is decided that applicable law precludes enforcement of any of this arbitration agreement’s limitations as to a particular claim for relief, then that claim (and only that claim) shall be severed from the arbitration and may be brought in court.  In addition, notwithstanding anything to the contrary, if any part of this agreement to arbitrate is deemed invalid or inapplicable, the remainder of the agreement to arbitrate shall still be considered valid and enforceable.
                        </p>
                        
                        <p>
                            Nothing herein shall be construed as consent by BYBE to the jurisdiction of any court regarding claims unrelated to the use of Services (including the Rewards program) or this Agreement.
                        </p>
                    </li>
    
                    <li>
                        <h2 className="text-xl md:text-3xl text-secondary font-secondary font-bold">
                            ARBITRATION PROCEDURES
                        </h2>

                        <p>
                            Arbitration shall be conducted by JAMS in accordance with its Streamlined Arbitration Rules and Procedures (the “JAMS Rules”).  The JAMS Rules and instructions for how to initiate an arbitration are available from JAMS at http://www.jamsadr.com or 1-800-352-5267.  To begin an arbitration proceeding, you must serve BYBE’s registered agent for service of process, BYBE, Inc. at PO Box 1621, Columbus, OH 43216
                        </p>
    
                        <p>
                            Payment of all filing, administration and arbitrator fees will be governed by the JAMS Rules, except that for claims of less than $1,000, you will be obligated to pay $25 and BYBE will pay all other administrative costs and fees.  In addition, for claims of less than $1,000, BYBE will reimburse you for the $25 fee if the arbitrator rules in your favor.  Reasonable attorneys’ fees and expenses will be awarded only to the extent such allocation or award is available under applicable law.
                        </p>
    
                        <p>
                            Any arbitration will take place in or near the county where claimant resides and will be determined by a single arbitrator; provided, however, that upon request by either party, the arbitration shall be conducted via telephone to the extent permitted by the JAMS Rules.  The arbitration may award on an individual basis the same damages and relief as a court (including injunctive relief).
                        </p>
    
                        <p>
                            Judgment on the award may be entered in any court of competent jurisdiction.
                        </p>
                    </li>
                </ul>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    SEVERANCE
                </h2>

                <p>
                    Notwithstanding anything herein to the contrary, if any part of this Agreement is deemed invalid or inapplicable, such provision shall be modified or restricted to the extent and in the manner necessary to render it valid, legal, and enforceable. If such provision cannot be so modified or restricted, it shall be excised from this Agreement without affecting the validity, legality, or enforceability of the remainder of this Agreement, which shall be fully enforced.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    MODIFICATION OF THE TERMS
                </h2>

                <p>
                    We reserve the right, at our discretion, to change the Terms on a going forward basis at any time. Please check the Terms periodically for changes. Your continued use of the Service after the changes become effective constitutes your binding acceptance of such changes. In the event that a change to the Terms materially modifies your rights or obligations, we will make an effort to notify you of the change, such as by sending you an email to the address we have on file for you, or presenting a pop-up window or other notification to you through the Service when you log in. Immaterial modifications are effective upon publication, and material changes will be effective upon the earlier of (a) continued use of the Service with actual knowledge of the modification, or (b) thirty (30) days following the change. For the avoidance of doubt, disputes arising hereunder will be resolved in accordance with the Terms in effect that the time the dispute arose.
                </p>
            </div>
            
            <div className="section">
                <h2 className="text-2xl md:text-3xl text-secondary font-secondary font-bold">
                    CONTACT INFORMATION
                </h2>
                
                <div>
                    <div>To ask questions or comment about these Terms of Use, contact us at:</div>
                    <div className="font-bold">BYBE INC</div>
                    <div>PO BOX 1621</div>
                    <div>Columbus, OH 43216</div>
                    <div>Email: <a href="mailto:info@bybeapp.com" className="text-primary hover:text-primary-light transition duration-300">info@bybeapp.com</a></div>
                </div>
            </div>
        
        </div>
    </div>
  </>;
}