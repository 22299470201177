import React from 'react';
import useNavigateGA from '../../hooks/useNavigateGA';

import {Link} from 'react-router-dom';

export default function({gaEvent, text, url, center, md, lg, inline, margin, marginRight, mobileInline, _blank, white, darkBG, gaEventCategory, gaEventAction, gaEventLabel, newWindow, reactLink}){
    
    const navigate = useNavigateGA();

    return <div className={`
        ${center ? "text-center" : ``} ${inline ? "inline-block" : ``} 
        ${margin ? "mx-4" : ``} ${marginRight ? "mr-4" : ``}
        ${mobileInline ? "inline-block" : ``}
    `}>
        
    { reactLink ?
        <Link to={url} onClick={event => navigate({event, gaEventCategory, gaEventAction, gaEventLabel, newWindow})} target={`${_blank ? "_blank" : `` }`}>
            <button className={`py-2 px-4 border-2  text-base md:text-lg height-[44px] rounded-lg drop-shadow-lg duration-500
                ${ white ? `border-white` : `border-light-purple` } 
                ${ white ? `text-white` : `text-light-purple` }
                ${ white & darkBG ? `hover:border-white/50 hover:text-white/50` : `hover:border-dark-purple hover:text-dark-purple`} 
                
                ${md ? "w-40" : ``}
                ${lg ? "w-64" : ``}`}>

                {text}
            </button>
        </Link>

    :

        <a href={url} onClick={event => navigate({event, gaEventCategory, gaEventAction, gaEventLabel, newWindow})} target={`${_blank ? "_blank" : `` }`}>
            <button className={`py-2 px-4 border-2  text-base md:text-lg height-[44px] rounded-lg drop-shadow-lg duration-500
                ${ white ? `border-white` : `border-light-purple` } 
                ${ white ? `text-white` : `text-light-purple` }
                ${ white & darkBG ? `hover:border-white/50 hover:text-white/50` : `hover:border-dark-purple hover:text-dark-purple`} 
                
                ${md ? "w-40" : ``}
                ${lg ? "w-64" : ``}`}>

                {text}
            </button>
        </a>
    }

    </div>
}