import React from 'react';
import useNavigateGA from '../../hooks/useNavigateGA';

export default function({gaEvent, text, center, md, lg, inline, margin, marginRight, mobileInline, _blank, white, email, subject = '', body = '', darkBG, gaEventCategory, gaEventAction, gaEventLabel}){
    
    const navigate = useNavigateGA();

    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <div className={`
            ${center ? "text-center" : ``} ${inline ? "inline-block" : ``} 
            ${margin ? "mx-4" : ``} ${marginRight ? "mr-4" : ``}
            ${mobileInline ? "inline-block" : ``}
        `}>
        <a href={`mailto:${email}${params}`} 
        onClick={event => navigate({event, gaEventCategory: gaEventCategory, gaEventAction: gaEventAction, gaEventLabel: gaEventLabel, newWindow: true})} 
        target={`${_blank ? "_blank" : `` }`}>
            <button className={`py-2 px-4 border-2  text-base md:text-lg height-[44px] rounded-lg drop-shadow-lg duration-500
               ${ white ? `border-white` : `border-light-purple` } 
               ${ white ? `text-white` : `text-light-purple` }
               ${ white & darkBG ? `hover:border-white/50 hover:text-white/50` : `hover:border-dark-purple hover:text-dark-purple`} 
                
                ${md ? "w-40" : ``}
                ${lg ? "w-64" : ``}`}>

                {text}
            </button>
        </a>
    </div>
}


