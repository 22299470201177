import React from 'react';
import useNavigateGA from '../../hooks/useNavigateGA';

import {Link} from 'react-router-dom';

export default function({text, url, center, mobileCenter, margin, marginRight, inline, mobileInline, md, lg, _blank, darkBG, gaEventCategory, gaEventAction, gaEventLabel, newWindow, reactLink, nowrap}){

    const navigate = useNavigateGA();

    return <div className={`
        ${center ? "text-center" : ''}
        ${inline ? "inline-block" : ''}
        ${mobileInline ? "inline-block" : ''}
        ${mobileCenter ? "text-center md:text-left" : ''} ${inline ? "inline-block" : ''}
        ${margin ? "mx-4" : ''} ${marginRight ? "mr-4" : ''}
    `}>

    {reactLink ?
        <Link className='inline-block' to={url}
            onClick={event => navigate({event, gaEventCategory, gaEventAction, gaEventLabel, newWindow})}
            target={`${_blank ? "_blank" : '' }`}>

            <button className={`py-2 px-4 bg-light-purple text-wrap font-bold text-white text-base md:text-lg rounded-lg height-[44px] drop-shadow-lg duration-500
                ${nowrap ? "whitespace-nowrap" : ''}
                ${darkBG ? `hover:bg-light-purple/75` : `hover:bg-dark-purple`}
                ${md ? "w-40" : ''}
                ${lg ? "w-64" : ''}`}>
                {text}
            </button>
        </Link>

    :

        <a className='inline-block' href={url}
            onClick={event => navigate({event, gaEventCategory, gaEventAction, gaEventLabel, newWindow})}
            target={`${_blank ? "_blank" : '' }`}>

            <button className={`py-2 px-4 bg-light-purple font-bold text-white text-base md:text-lg rounded-lg height-[44px] drop-shadow-lg duration-500
                ${darkBG ? `hover:bg-light-purple/75` : `hover:bg-dark-purple`}
                ${md ? "w-40" : ''}
                ${lg ? "w-64" : ''}`}>
                {text}
            </button>
        </a>
    }


    </div>

}