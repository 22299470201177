import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export default function useScrollToRefSection(sectionRefs) {

    const location = useLocation();

    useEffect(() => {
        if (location.hash && Object.keys(sectionRefs).length) {
            setTimeout(() => {
                const anchor = location.hash.replace('#', '');
                if (sectionRefs[anchor]) sectionRefs[anchor].current.scrollIntoView();
            }, 100);
        }
    },[location, sectionRefs]);

}