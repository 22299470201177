import React, {useEffect, useRef} from 'react';

import lottie from 'lottie-web';

import ButtonPrimary from './components/buttons/buttonPrimary.jsx';
import ButtonSecondary from './components/buttons/buttonSecondary.jsx';
import H2 from './components/headings/h2.jsx'
import H3 from './components/headings/h3.jsx'
import Description from './components/texts/description.jsx'
import Body from './components/texts/body.jsx'
import useScrollToRefSection from './hooks/useScrollToRefSection.jsx';

import lottieDashboardJson from '../src/js/animations/lottieDashboardFast.json';
import lottieAPIJson from '../src/js/animations/lottieAPIFast.json';
import lottieDirectJson from '../src/js/animations/lottieDirectFast.json';
import lottieAppJson from '../src/js/animations/lottieAppFast.json';
import lottieEngageJson from '../src/js/animations/lottieEngageFast.json';

export default function Products(){

    let lottieDashboardContainer = React.createRef();
    let lottieAPIContainer = React.createRef();
    let lottieDirectContainer = React.createRef();
    let lottieAppContainer = React.createRef();
    let lottieEngageContainer = React.createRef();

    useEffect(() => {
        lottie.loadAnimation({
          container: lottieDashboardContainer.current,
          animationData: lottieDashboardJson
        })
        
        lottie.loadAnimation({
          container: lottieAPIContainer.current,
          animationData: lottieAPIJson
        })
        
        lottie.loadAnimation({
          container: lottieDirectContainer.current,
          animationData: lottieDirectJson
        })
        
        lottie.loadAnimation({
          container: lottieAppContainer.current,
          animationData: lottieAppJson
        })
        
        lottie.loadAnimation({
          container: lottieEngageContainer.current,
          animationData: lottieEngageJson
        })
      }, []);
    
    const sectionRefs = {
        api: useRef(),
        direct: useRef(),
        bybeApp: useRef(),
        engage: useRef()
    }

    useScrollToRefSection(sectionRefs);

    return <>
        <div className='container mx-auto px-4 md:px-0 mt-8 md:mt-16'>
            <div className='mb-5 text-4xl md:text-6xl lg:text-8xl font-bold md:leading-[1.1] tracking-[-0.05em] text-dark-purple flex items-center gap-x-5 md:gap-x-10'>
                <div ref={lottieDashboardContainer} className='h-[50px] md:h-[100px] w-[50px] md:w-[100px]' />
                Dashboard
            </div>

            <Description>
                <span className='block md:w-2/3'>
                    Our dashboard is the central hub for offer management and distribution. We build tools constantly, reinventing best practices on how to analyze and strategize the best promotions. From payments, results, conversion rates, to state gates, manage it all through our dashboard.
                </span>
            </Description>

            <div className='mb-16 md:mb-24 md:w-3/4'>
                <img src="/images/browser-top2.png" alt="" />
                <video className='max-h-full drop-shadow-lg' playsInline autoPlay muted loop><source src="/videos/offer-performance.mp4" type="video/mp4" />Your browser does not support the video tag.</video>
            </div>

            <H2>Offer management</H2>

            <Description>
                <span className='block md:w-2/3'>
                    Before an offer goes live, organize and develop an activation plan with an easy-to-use process and built-in features. Our dashboard is a one-stop shop for offer management with legal limitations incorporated into offer setups.
                </span>
            </Description>

            <ul className='mb-10 grid grid-cols-2 gap-10'>
                <li>
                    <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/delivery.svg" />
                
                    <H3>Offer distribution and state-by-state compliance</H3>
                
                    <Body>Easily manage promotion distribution by state, and align offers with product availability in markets and retailers through a central channel.</Body> 
                </li>
                
                <li>
                    <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/target-revenue.svg" />
                
                    <H3>Budget control</H3>
                
                    <Body>Define and cap an offer budget before it hits the market.</Body> 
                </li>

                <li>
                    <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/calendar2.svg" />
                
                    <H3>Offer management calendar</H3>
                
                    <Body>Review upcoming, active, and historical offer schedules with our easily digestible calendar format.</Body> 
                </li>

                <li>
                    <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/clock2.svg" />
                
                    <H3>Historical redemption data</H3>
                
                    <Body>Access offer redemption data and easily export and share with your team for analysis.</Body> 
                </li>

                <li>
                    <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/devices.svg" />
                
                    <H3>Promotion preview</H3>
                
                    <Body>Review the appearance, feel, and terms of an offer before it goes live.</Body> 
                </li>

                <li>
                    <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/sign.svg" />
                
                    <H3>In-store sign templates</H3>
                
                    <Body>Download signs for easy printing and activation across retail, brand and BYBE applications.</Body> 
                </li> 
            </ul>

            <div className='mb-16 md:mb-24 md:w-3/4'>
                <img src="/images/browser-top3.png" alt="" />
                <video className='max-h-full drop-shadow-lg' title="With the BYBE dashboard you can easily access product and consumer data" playsInline autoPlay muted loop>
                    <source src="/videos/redemptions-by-state-store.mp4" type="video/mp4" />Your browser does not support the video tag.</video>
            </div>
            
            <H2>Offer recap and analysis</H2>
                        
            <Description>When offers are live, keep track of performance. After promotions end, analyze results with easily exportable functions to add within outside datasets (i.e. transaction and distributor data).</Description>
            
            <div className='mb-16 md:mb-24'>
                <ul className='mt-10 md:mt-0 grid grid-cols-2 md:grid-cols-0 gap-y-10 gap-10'>
                    <li>
                        <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/calculator2.svg" />
                    
                        <H3>Clearinghouse / billing</H3>
                
                        <Body>No third party clearinghouse is required. We manage consumer payouts directly and bill you monthly.</Body>
                    </li>
                    
                    <li>
                        <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/screen.svg" />
                    
                        <H3>Automated recaps</H3>
                
                        <Body>Promotion results delivered directly to your inbox.</Body>
                    </li>

                    <li>
                        <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/lock.svg" />
                    
                        <H3>Fraud prevention</H3>
                
                        <Body>We develop proprietary processes to minimize fraudulent user activity.</Body>
                    </li>

                    <li>
                        <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/timer.svg" />
                    
                        <H3>Real-time redemption data</H3>
                
                        <Body>24/7 access to offer performance.</Body>
                    </li>

                    <li>
                        <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/cogs.svg" />
                    
                        <H3>Approval rules engine</H3>
                
                        <Body>Our rules engine approves and denies redemptions from transaction data pulled directly from retailer POS systems.</Body>
                    </li>

                    <li>
                        <img className='mb-5 w-[50px] h-[50px]' src="/images/icons/management.svg" />
                    
                        <H3>Compliance management</H3>
                
                        <Body>AML, KYC, Escheatment: built-in policies we actively manage.</Body>
                    </li>
                </ul>
            </div>
        </div>

        <div className='mx-4 mb-16 md:mb-24'>
            <H2 center>Want to see the dashboard in action?</H2>
            
            <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'products-page-click'} gaEventAction={'click-to-schedule-a-demo'} newWindow text="Schedule a demo" center _blank />
        </div>

        <div className='gradient text-gray-blue'>
            <div className='container mx-auto px-4 md:px-0'>
                <div ref={sectionRefs['api']} className='pt-16 md:pt-24 mb-16 md:mb-24'>
                    <div className='mb-5 text-4xl md:text-6xl lg:text-8xl font-bold md:leading-[1.1] tracking-[-0.05em] text-white flex items-center gap-x-5 md:gap-x-10'>
                        <div ref={lottieAPIContainer} className='h-[50px] md:h-[100px] w-[50px] md:w-[100px]' />
                        API
                    </div>
                    
                    <Description threeFourths>Our API distributes offers in retail apps on our network. All promotions are cash back and delivered to the consumer in a customizable post-purchase payout flow. Email delivery rates are 99.8%.</Description>
                    
                    <div className='flex gap-10 mb-10 flex-wrap justify-center'>
                        <img className='w-[400px]' src="/images/screenshots/bybe-api/retailer-screens-1.png" alt="Mobile screenshots of clipping an offer for an adult beverage" />
                        <img className='w-[400px]' src="/images/screenshots/bybe-api/retailer-screens-2.png" alt="Mobile screenshots of clipping an offer for an adult beverage" />
                        <img className='w-[400px]' src="/images/screenshots/bybe-api/retailer-screens-3.png" alt="Mobile screenshots of clipping an offer for an adult beverage" />
                    </div>
                    
                    <div className='md:flex justify-center md:space-x-10 space-y-4 md:space-y-0 text-center md:text-left'>
                            <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'products-page-click'} gaEventAction={'click-to-req-demo--api'} newWindow text="Request demo" _blank margin lg darkBG/>

                            <ButtonSecondary white url={"/developers"} gaEventCategory={'products-page-click'} gaEventAction={'click-to-documentation'} text="View more information" margin lg darkBG/>
                    </div>
                </div>

                <div ref={sectionRefs['direct']} className='mb-16 md:mb-24'>
                    <div className='mb-5 text-4xl md:text-6xl lg:text-8xl font-bold md:leading-[1.1] tracking-[-0.05em] text-white flex items-center gap-x-5 md:gap-x-10'>
                        <div ref={lottieDirectContainer} className='h-[50px] md:h-[100px] w-[50px] md:w-[100px]' />
                        Direct
                    </div>
                    
                    <div className='flex space-x-10 md:space-x-24 mb-10'>
                        <div className='w-1/2 md:w-3/5'>
                            <Description>
                                Supplier promotions should live wherever consumers seek product info. Since the pandemic, more consumers are building direct digital relationships with their favorite brands. Leverage promotions to drive engagement and value on supplier websites.
                            </Description>
                            
                            <img src="/images/website-mockup-top.png" alt='BYBE Direct example on an iPad device' className='drop-shadow-lg hidden md:block'/>

                            <img src="/images/screenshots/bybe-direct/bybeDirectiPad.jpg" alt='BYBE Direct example on an iPad device' className='drop-shadow-lg hidden md:block'/> 
                        </div>
                        <div className='w-1/2 md:w-1/5'>
                            <img src="/images/screenshots/bybe-direct/bybeDirectiPhone.png" alt='BYBE Direct example on an Phone device' className='drop-shadow-lg' />
                        </div>
                    </div>
                    
                    <div className='md:flex justify-center md:space-x-10 space-y-4 md:space-y-0 text-center md:text-left'>
                            <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'products-page-click'} gaEventAction={'click-to-req-demo--direct'} newWindow text="Request demo" margin lg _blank darkBG/>

                            <ButtonSecondary white url={"https://yuengling.byberebates.com/"} gaEventCategory={'products-page-click'} gaEventAction={'click-to-view-example--direct'} newWindow text="View example" margin lg _blank darkBG/>
                    </div>
                </div>

                <div ref={sectionRefs['bybeApp']} className='mb-16 md:mb-24'>
                    <div className='mb-5 text-4xl md:text-6xl lg:text-8xl font-bold md:leading-[1.1] tracking-[-0.05em] text-white flex items-center gap-x-5 md:gap-x-10'>
                        <div ref={lottieAppContainer} className='h-[50px] md:h-[100px] w-[50px] md:w-[100px]' />
                        BYBE App
                    </div>

                    <div className='flex space-x-10 md:space-x-24 mb-10'>
                        <div className='w-1/2 md:w-3/5'>
                            <Description>
                                We ensure every offer is available to every consumer and retailer. Consumers receive cash back in 48 hours. The BYBE app is a testing site for new features, offer strategies and user functionality.
                            </Description>

                            <img src="/images/website-mockup-top.png" alt='BYBE Direct example on an iPad device' className='drop-shadow-lg hidden md:block'/>    
                            <img src="/images/screenshots/bybe-app/bybeAppiPad.jpg" alt='BYBE app example on an iPad device' className='drop-shadow-lg hidden md:block'/> 
                        </div>

                        <div className='w-1/2 md:w-1/5'>
                            <img src="/images/screenshots/bybe-app/bybeAppiPhone.png" alt="alt='BYBE app example on an Phone device'" className='drop-shadow-lg'/>
                        </div>
                    </div>
                    
                    <div className='md:flex justify-center md:space-x-10 space-y-4 md:space-y-0 text-center md:text-left'>
                        <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'products-page-click'} gaEventAction={'click-to-req-demo--bybeapp'} newWindow text="Request demo" _blank margin lg darkBG/>

                        <ButtonSecondary white url={"https://BYBEapp.com/"} gaEventCategory={'products-page-click'} gaEventAction={'click-to-view-example--bybeapp'} newWindow text="View BYBE app" _blank margin lg darkBG/>
                    </div>
                </div>

                <div ref={sectionRefs['engage']} className='pb-16 md:pb-24'>
                    <div className='mb-5 text-4xl md:text-6xl lg:text-8xl font-bold md:leading-[1.1] tracking-[-0.05em] text-white flex items-center gap-x-5 md:gap-x-10'>
                        <div ref={lottieEngageContainer} alt="" className='h-[50px] md:h-[100px] w-[50px] md:w-[100px]' />
                        Engage
                    </div>
                    
                    <div className='flex space-x-10 md:space-x-24 mb-10'>
                        <div className='w-1/2 md:w-3/5'>
                            <Description>
                                <span className='mb-10 block'>We drive consumers to promotions through direct digital marketing. We use targeted ads, SEO, and social media to amplify savings. Consumers sign up for email updates and marketing initiatives.</span>  
                            </Description>

                            <img src="/images/website-mockup-top.png" alt='BYBE Direct example on an iPad device' className='drop-shadow-lg hidden md:block'/>    
                            <img src="/images/screenshots/bybe-engage/bybeEngageiPad.jpg" alt='BYBE Engage example on an iPad device' className='drop-shadow-lg hidden md:block'/> 
                        </div>

                        <div className='w-1/2 md:w-1/5'>
                            <img src="/images/screenshots/bybe-engage/iphone.png" alt='BYBE Engage example on an Phone device' className='drop-shadow-lg'/>
                        </div>
                    </div>
                    
                    <div className='md:flex justify-center md:space-x-10 space-y-4 md:space-y-0 text-center md:text-left'>
                        <ButtonPrimary url={'mailto:BYBE.DEMO@bybeapp.com'} gaEventCategory={'products-page-click'} gaEventAction={'click-to-req-demo--engage'} newWindow text="Request demo" margin lg _blank darkBG/>
                    
                        <ButtonSecondary white url={'https://adultbevoffers.com/'} gaEventCategory={'products-page-click'} gaEventAction={'click-to-view-example--engage'} newWindow text="View example" margin lg _blank darkBG/>      
                    </div>
                </div>
            </div>
        </div>
    </>;
}